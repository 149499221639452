import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, Link, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import dateFormat from "dateformat";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label, 
  Col,
  CardBody
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ReportIndex = (props) => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  const {category} = useParams();
  const currentPage = window.location.pathname.split('/')[2]

  const [level, setLevel] = useState('');
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [report, setReport] = useState({});
  const [reportChartDaily, setReportChartDaily] = useState([]);
  const [reportChartMonthly, setReportChartMonthly] = useState([]);
  const [reportChartMember, setReportChartMember] = useState([]);
  const [reportChartCity, setReportChartCity] = useState([]);
  const [reportChartProduct, setReportChartProduct] = useState([]);
  const [reportChartExpedition, setReportChartExpedition] = useState([]);
  const [reportChartSpending, setReportChartSpending] = useState([]);

  let newDate = new Date()
  const [byMonth, setByMonth] = useState(newDate.getMonth()+1);
  const [byYear, setByYear] = useState(newDate.getFullYear());
  const [years, setYears] = useState([]);

  const [activeNav, setActiveNav] = useState(1);
  const [chartData, setChartData] = useState("data1");

  const [offsetMember, setOffsetMember] = useState(1);
  const [pagingMember, setPagingMember] = useState(true);
  const [loadMoreMember, setLoadMoreMember] = useState(true);

  const [offsetCity, setOffsetCity] = useState(1);
  const [pagingCity, setPagingCity] = useState(true);
  const [loadMoreCity, setLoadMoreCity] = useState(true);

  const [offsetProduct, setOffsetProduct] = useState(1);
  const [pagingProduct, setPagingProduct] = useState(true);
  const [loadMoreProduct, setLoadMoreProduct] = useState(true);
  
  const [spinnerOrderDaily, setSpinnerOrderDaily] = useState(true);
  const [spinnerOrderMonthly, setSpinnerOrderMonthly] = useState(true);
  const [spinnerOrderMember, setSpinnerOrderMember] = useState(true);
  const [spinnerOrderCity, setSpinnerOrderCity] = useState(true);
  const [spinnerOrderProduct, setSpinnerOrderProduct] = useState(true);
  const [spinnerOrderExpedition, setSpinnerOrderExpedition] = useState(true);
  const [spinnerSpending, setSpinnerSpending] = useState(true);

  const hashMonth = [
    [1, "Jan"], [2, "Feb"], [3, "Mar"], [4, "Apr"], [5, "May"], [6, "Jun"], [7, "Jul"], [8, "Aug"], [9, "Sep"], [10, "Oct"], [11, "Nov"], [12, "Dec"]
  ]

  const hashYear = () => {
    let i = 2021;
    let arr = [];
    while (i <= newDate.getFullYear()) {
      arr.push(i)
      i++;
    }
    setYears(arr);
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const [id, setId] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone1, setPhone1] = useState('');
  const [instagram, setInstagram] = useState('');
  // const [linkMarketplace1, setLinkMarketplace1] = useState('');
  const [lastFollowup, setLastFollowup] = useState('');
  const [lastFollowupDate, setLastFollowupDate] = useState('');
  const [lastFollowupNote, setLastFollowupNote] = useState('');

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [spinnerSave, setSpinnerSave] = useState(false);

  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const toggleExport = () => {
    setModalExport(!modalExport);
  }

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = reportChartMember.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setUsername(dt.username);
        setEmail(dt.email);
        setFullName(dt.full_name);
        setPhone1(dt.phone1);
        setInstagram(dt.instagram);
        // setLinkMarketplace1(dt.link_marketplace1);
        setLastFollowup(dt.last_followup);
        setLastFollowupDate(dt.last_followup_date);
        setLastFollowupNote(dt.last_followup_note);
      }
    }
    setModal(!modal);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('username', username);
    fd.append('email', email);
    fd.append('full_name', fullName);
    fd.append('phone1', phone1);
    fd.append('instagram', instagram);
    // fd.append('link_marketplace1', linkMarketplace1);
    fd.append('last_followup', lastFollowup);
    fd.append('last_followup_date', lastFollowupDate);
    fd.append('last_followup_note', lastFollowupNote);
    
    if(id){
      API.put(`users/${id}`, fd, config )
        .then(res => {
          let data = res.data.data;
          setSpinnerSave(false);
          loadData();
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const loadData = (start_date, end_date, by_month, by_year, level) => {    
    if(currentPage && currentPage === 'daily_report'){
      setChartData("data" + 1);
      setActiveNav(1)
      loadChartDaily(by_month, by_year, level);
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid', category_date: 'paid_date', by_month: by_month, by_year: by_year, by_level: level}};
      loadReport(config)
    }else if(currentPage && currentPage === 'monthly_report'){
      setChartData("data" + 2);
      setActiveNav(2)
      loadChartMonthly(by_month, by_year, level);
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid', category_date: 'paid_date', by_year: by_year, by_level: level}};
      loadReport(config)
    }else if(currentPage && currentPage === 'member_report'){
      loadChartMember(start_date, end_date, level);
    }else if(currentPage && currentPage === 'region_report'){
      loadChartCity(start_date, end_date,level);
    }else if(currentPage && currentPage === 'product_report'){
      loadChartProduct(start_date, end_date);
    }else if(currentPage && currentPage === 'expedition_report'){
      loadChartExpedition(start_date, end_date);
    }else if(currentPage && currentPage === 'spending_report'){
      loadChartSpending(start_date, end_date);
    }
  }

  const loadReport = (config) =>{
    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      }).catch(error => {
        console.log(error.response);
      })
  }

  const updateSearchLevel = (s) => {
    if(s === '' ){
      setLevel('');
    }else{
      setLevel(s);
    }
    loadData(searchStartDate, searchEndDate, byMonth, byYear, s);
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(start_date, searchEndDate, byMonth, byYear, level);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(searchStartDate, end_date, byMonth, byYear, level);
  }

  const updateByMonth = (month) => {
    setByMonth(month)
    loadData(searchStartDate, searchEndDate, month, byYear, level);
  }

  const updateByYear = (year) => {
    setByYear(year)
    loadData(searchStartDate, searchEndDate, byMonth, year, level);
  }

  const loadChartDaily = (by_month, by_year, level) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false, category: 'group_by_daily_order', category_date: 'paid_date', by_month: by_month, by_year: by_year, by_level: level }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderDaily(false);
        const data = res.data.report;
        setReportChartDaily(data);
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMonthly = (by_month, by_year, level) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false, category: 'group_by_monthly_order', category_date: 'paid_date', by_year: by_year, by_level: level }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMonthly(false);
        const data = res.data.report;
        setReportChartMonthly(data);
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMember = (start_date, end_date, level) =>{
    setOffsetMember(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, category: 'group_by_member', category_date: 'paid_date', start_at: start_date, end_at: end_date, by_level: level }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMember(false);
        const data = res.data.report;
        setReportChartMember(data);
        setLoadMoreMember(false);
        if(res.data.total_pages <= 1){
          setPagingMember(false);
          console.log('No paging')
        }else{
          setPagingMember(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMemberMore = () =>{
    setLoadMoreMember(true);
    setOffsetMember(offsetMember+1)
    let offset_page = offsetMember+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, category: 'group_by_member', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate, by_level: level }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMember(false);
        const data = res.data.report;
        setLoadMoreMember(false);
        setReportChartMember([...reportChartMember, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingMember(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartCity = (start_date, end_date, level) =>{
    setOffsetCity(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, category: 'group_by_city', category_date: 'paid_date', start_at: start_date, end_at: end_date, by_level: level }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderCity(false);
        const data = res.data.report;
        setReportChartCity(data);
        setLoadMoreCity(false);
        if(res.data.total_pages <= 1){
          setPagingCity(false);
          console.log('No paging')
        }else{
          setPagingCity(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartCityMore = () =>{
    setLoadMoreCity(true);
    setOffsetCity(offsetCity+1)
    let offset_page = offsetCity+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, category: 'group_by_city', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate, by_level: level }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderCity(false);
        const data = res.data.report;
        setLoadMoreCity(false);
        setReportChartCity([...reportChartCity, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingCity(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartProduct = (start_date, end_date) =>{
    setOffsetProduct(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, category: 'group_by_product', category_date: 'paid_date', start_at: start_date, end_at: end_date }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderProduct(false);
        const data = res.data.report;
        setReportChartProduct(data);
        setLoadMoreProduct(false);
        if(res.data.total_pages <= 1){
          setPagingProduct(false);
          console.log('No paging')
        }else{
          setPagingProduct(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartProductMore = () =>{
    setLoadMoreProduct(true);
    setOffsetProduct(offsetProduct+1)
    let offset_page = offsetProduct+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, category: 'group_by_product', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderProduct(false);
        const data = res.data.report;
        setLoadMoreProduct(false);
        setReportChartProduct([...reportChartProduct, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingProduct(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartExpedition = (start_date, end_date) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false, category: 'group_by_expedition', category_date: 'paid_date', start_at: start_date, end_at: end_date }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderExpedition(false);
        const data = res.data.report;
        setReportChartExpedition(data);
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartSpending = (start_date, end_date) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false, category: 'analysis_spending', start_at: start_date, end_at: end_date }};
    API.get(`spending_analysis_reports`, config)
      .then(res => {
        setSpinnerSpending(false);
        const data = res.data.report;
        setReportChartSpending(data);
      }).catch(error => {
        console.log(error.response);
      })
  }

  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  let chartOrderCount = {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: reportChartMonthly.map((d) => dateFormat(d.month, "mm/yyyy")).reverse(),
      datasets: [
        {
          label: "Sales",
          data: reportChartMonthly.map((d) => d.count).reverse(),
          maxBarThickness: 10,
        },
      ],
    },
  };

  let chartOrderSum = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return numberFormat(value);
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
  
            if (data.datasets.length > 1) {
              content += label;
            }
  
            content += numberFormat(yLabel);
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: reportChartDaily.map((d) => d.date),
        datasets: [
          {
            label: "Performance",
            data: reportChartDaily.map((d) => (parseInt(d.store_price)-parseInt(d.virtual_spendings)) ),
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: reportChartMonthly.map((d) => dateFormat(d.month, "mm/yyyy")).reverse(),
        datasets: [
          {
            label: "Performance",
            data: reportChartMonthly.map((d) => (parseInt(d.store_price)-parseInt(d.virtual_spendings)) ).reverse(),
          },
        ],
      };
    },
  };

  const exportData = () => {
    let path_report = '';
    let ca = '';

    let _by_month = byMonth;
    let _by_year = byYear;

    if(currentPage && currentPage === 'daily_report'){
      path_report = 'report_export';
      ca = 'group_by_daily_order';
    }else if(currentPage && currentPage === 'monthly_report'){
      path_report = 'report_export';
      ca = 'group_by_monthly_order';
      _by_month = ''
    }else if(currentPage && currentPage === 'member_report'){
      path_report = 'report_export';
      ca = 'group_by_member';
      _by_month = '';
      _by_year = '';
    }else if(currentPage && currentPage === 'region_report'){
      path_report = 'report_export';
      ca = 'group_by_city';
    }else if(currentPage && currentPage === 'product_report'){
      path_report = 'report_export';
      ca = 'group_by_product';
    }else if(currentPage && currentPage === 'expedition_report'){
      path_report = 'report_export';
      ca = 'group_by_expedition';
    }else if(currentPage && currentPage === 'spending_report'){
      path_report = 'spending_report_export';
      ca = 'analysis_spending';
    }

    toggleExport();
    setLoadingExport(true);
    
    const config = { headers: { Authorization: `Bearer ${token}` }, params: {pagination: false, category: ca, start_at: searchStartDate, end_at: searchEndDate, by_level: level, by_year: _by_year, by_month: _by_month } };
    API.get(path_report, config)
      .then(res => {
        const data = res.data.report;
        setSpinnerSpending(false)
        setLoadingExport(false);
        toggleExport();
        setLinkDownload(data);
      })
      .catch(error => {
        setSpinnerSpending(false)
        setLoadingExport(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }
    
    hashYear();
    loadData(searchStartDate, searchEndDate, byMonth, byYear);

  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Mitra {fullName}</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Instagram</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        @
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="@nama_instagram"
                      value={instagram} onChange={e => setInstagram(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Status FollowUp</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={lastFollowup} onChange={e => setLastFollowup(e.target.value)}>
                      <option value=''>Status FollowUp</option>
                      <option value='No Tidak Bisa di Hubungi'>No Tidak Bisa di Hubungi</option>
                      <option value='Susah Komunikasi'>Susah Komunikasi</option>
                      <option value='Kendala Berjualan'>Kendala Berjualan</option>
                      <option value='Penjualan Aktif'>Penjualan Aktif</option>
                      <option value='Penjualan Meningkat'>Penjualan Meningkat</option>
                      <option value='Sudah Tidak Jualan'>Sudah Tidak Jualan</option>
                      <option value='Tidak Minat Jadi Mitra'>Tidak Minat Jadi Mitra</option>
                      <option value='Lain-Lain'>Lain-Lain</option>
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>FollowUp Terakhir</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker3" value={lastFollowupDate} onChange={e => setLastFollowupDate(e)} />
                  </FormGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>FollowUp Subyek</Label>
                  <Input type="select" value={lastFollowupNote} onChange={e => setLastFollowupNote(e.target.value)}>
                    <option value=''>Jenis Subyek</option>
                    <option value='Sampaikan Promo'>Sampaikan Promo</option>
                    <option value='Penawaran Naik Level'>Penawaran Naik Level</option>
                    <option value='Curhat Lewat Chat'>Curhat Lewat Chat</option>
                    <option value='Curhat Lewat Telpon'>Curhat Lewat Telpon</option>
                    <option value='Bantu Strategi Jualan'>Bantu Strategi Jualan</option>
                    <option value='Broadcast Pengumuman'>Broadcast Pengumuman</option>
                    <option value='Subyek Lain-Lain'>Subyek Lain-Lain</option>
                  </Input>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
      </div>

      <Container className="mt--7" fluid>
        <Row>
          {
            activeNav === 1 && currentPage === 'daily_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="bg-gradient-default shadow mb-20">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col-sm-6">
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Overview
                        </h6>
                        <h2 className="text-white mb-0">Report Bulanan</h2>
                      </div>
                      <div className="col-sm-6">
                        <Row>
                          <div className='col-sm-4'>
                            <input type='text' placeholder='Level Mitra' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                          </div>
                          <div className='col-sm-4 datepicker-chart'>
                            <Input type="select" value={byMonth} onChange={e => updateByMonth(e.target.value)}>
                              <option value=''>Pilih Bulan</option>
                              { hashMonth ? (
                                  hashMonth.map((c) => {
                                    return (
                                      <option key={c[0]} value={c[0]}>{c[1]}</option>
                                    )
                                  })
                                ) : null
                              }
                            </Input>
                          </div>
                          <div className='col-sm-4 datepicker-chart'>
                            <Input type="select" value={byYear} onChange={e => updateByYear(e.target.value)}>
                              <option value=''>Pilih Tahun</option>
                              { years ? (
                                  years.map((c) => {
                                    return (
                                      <option key={c} value={c}>{c}</option>
                                    )
                                  })
                                ) : null
                              }
                            </Input>
                          </div>
                        </Row>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line
                        data={chartOrderSum[chartData]}
                        options={chartOrderSum.options}
                        getDatasetAtEvent={(e) => console.log(e)}
                      />
                    </div>
                  </CardBody>
                </Card>
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col-sm-10 col-6">
                        <h3 className="mb-0">Detail Report Bulanan</h3>
                      </div>
                      <div className="col-sm-2 col-6">
                        <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e', float: 'right'}}><i class="fas fa-download"></i> Export</Button>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerOrderDaily ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartDaily && reportChartDaily.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Tangal</th>
                              <th scope="col">Pesanan</th>
                              <th scope="col">Omset Penjualan</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Perkiraan HPP</th>
                              ) }
                              <th scope="col">Subsidi</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <>
                                  <th scope="col">Margin</th>
                                  <th scope="col">Income</th>
                                  <th scope="col">Pengeluaran</th>
                                  <th scope="col">Laba Bersih</th>
                                </>
                              ) }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartDaily.map((u) => {
                                return(
                                  <tr key={u.date}>
                                    <td>{dateFormat(u.date, "dd-mm-yyyy")}</td>
                                    <td>{numberFormat(u.count)}</td>
                                    <td>+{numberFormat( (parseInt(u.store_price)-parseInt(u.virtual_spendings)) )}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>-{numberFormat(u.basic_price)}</td>
                                    ) }
                                    <td>-{numberFormat(u.subsidi)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <>
                                        <td>{numberFormat(parseInt(u.store_price)-parseInt(u.virtual_spendings)-parseInt(u.basic_price)-parseInt(u.subsidi))}</td>
                                        <td>+{numberFormat(parseInt(u.virtual_incomes ? u.virtual_incomes : 0))}</td>
                                        <td>-{numberFormat(parseInt(u.spendings ? u.spendings : 0))}</td>
                                        <td>{numberFormat( (parseInt(u.store_price)-parseInt(u.virtual_spendings)-parseInt(u.basic_price)-parseInt(u.subsidi)) - (parseInt(u.spendings ? u.spendings : 0)+parseInt(u.virtual_money ? u.virtual_money : 0)) + parseInt(u.virtual_incomes ? u.virtual_incomes : 0) )}</td>
                                      </>
                                    ) }
                                  </tr>
                                )
                              })
                            }

                            <tr>
                              <td><b>TOTAL</b></td>
                              <td><b>{numberFormat(report.order_count)}</b></td>
                              <td><b>+{numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings))}</b></td>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <td><b>-{numberFormat(report.order_basic)}</b></td>
                              ) }
                              <td><b>-{numberFormat(report.subsidi)}</b></td>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <>
                                  <td><b>{numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)-parseInt(report.order_basic)-parseInt(report.subsidi))}</b></td>
                                  <td><b>+{numberFormat(parseInt(report.virtual_incomes ? report.virtual_incomes : 0))}</b></td>
                                  <td><b>-{numberFormat(parseInt(report.spendings ? report.spendings : 0)+parseInt(report.virtual_spendings ? report.virtual_spendings : 0))}</b></td>
                                  <td><b>{numberFormat( (parseInt(report.order_store)-parseInt(report.virtual_spendings)-parseInt(report.order_basic)-parseInt(report.subsidi)) - (parseInt(report.spendings ? report.spendings : 0)) + parseInt(report.virtual_incomes ? report.virtual_incomes : 0) )}</b></td>
                                </>
                              ) }
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : activeNav === 2 && currentPage === 'monthly_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="bg-gradient-default shadow mb-20">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col-sm-8">
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Overview
                        </h6>
                        <h2 className="text-white mb-0">Report Tahunan</h2>
                      </div>
                      <div className="col-sm-4">
                        <Row>
                          <div className='col-sm-6'>
                            <input type='text' placeholder='Level Mitra' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                          </div>
                          <div className='col-sm-6 datepicker-chart'>
                            <Input type="select" value={byYear} onChange={e => updateByYear(e.target.value)}>
                              <option value=''>Pilih Tahun</option>
                              { years ? (
                                  years.map((c) => {
                                    return (
                                      <option key={c} value={c}>{c}</option>
                                    )
                                  })
                                ) : null
                              }
                            </Input>
                          </div>
                        </Row>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line
                        data={chartOrderSum[chartData]}
                        options={chartOrderSum.options}
                        getDatasetAtEvent={(e) => console.log(e)}
                      />
                    </div>
                  </CardBody>
                </Card>
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col-sm-10 col-6">
                        <h3 className="mb-0">Detail Report Tahunan</h3>
                      </div>
                      <div className="col-sm-2 col-6">
                        <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e', float: 'right'}}><i class="fas fa-download"></i> Export</Button>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerOrderMonthly ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartMonthly && reportChartMonthly.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Bulan</th>
                              <th scope="col">Pesanan</th>
                              <th scope="col">RO Mitra</th>
                              <th scope="col">Omset Penjualan</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Perkiraan HPP</th>
                              ) }
                              <th scope="col">Subsidi</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <>
                                  <th scope="col">Margin</th>
                                  <th scope="col">Income</th>
                                  <th scope="col">Pengeluaran</th>
                                  <th scope="col">Laba Bersih</th>
                                </>
                              ) }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartMonthly.map((u) => {
                                return(
                                  <tr key={u.month}>
                                    <td>{dateFormat(u.month, "mmmm, yyyy")}</td>
                                    <td>{numberFormat(u.count)}</td>
                                    <td>{numberFormat(u.count_order_members)} / {numberFormat(u.count_members)}</td>
                                    <td>+{numberFormat( (parseInt(u.store_price)-parseInt(u.virtual_spendings)) )}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>-{numberFormat(u.basic_price)}</td>
                                    ) }
                                    <td>-{numberFormat(u.subsidi)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <>
                                        <td>{numberFormat( (parseInt(u.store_price)-parseInt(u.virtual_spendings))-parseInt(u.subsidi) )}</td>
                                        <td>+{numberFormat(parseInt(u.virtual_incomes ? u.virtual_incomes : 0))}</td>
                                        <td>-{numberFormat(parseInt(u.spendings ? u.spendings : 0))}</td>
                                        <td>{numberFormat( ((parseInt(u.store_price)-parseInt(u.virtual_spendings))-parseInt(u.subsidi)) - (parseInt(u.spendings ? u.spendings : 0)) + parseInt(u.virtual_incomes ? u.virtual_incomes : 0) )}</td>
                                      </>
                                    ) }
                                  </tr>
                                )
                              })
                            }

                            <tr>  
                              <td><b>TOTAL</b></td>
                              <td><b>{numberFormat(report.order_count)}</b></td>
                              <td><b>{numberFormat(report.count_order_members)} Mitra</b></td>
                              <td><b>+{numberFormat( parseInt(report.order_store)-parseInt(report.virtual_spendings) )}</b></td>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <td><b>-{numberFormat(report.order_basic)}</b></td>
                              ) }
                              <td><b>-{numberFormat(report.subsidi)}</b></td>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <>
                                  <td><b>{numberFormat(parseInt(report.order_store)-parseInt(report.order_basic)-parseInt(report.subsidi))}</b></td>
                                  <td><b>+{numberFormat(parseInt(report.virtual_incomes ? report.virtual_incomes : 0))}</b></td>
                                  <td><b>-{numberFormat(parseInt(report.spendings ? report.spendings : 0)+parseInt(report.virtual_spendings ? report.virtual_spendings : 0))}</b></td>
                                  <td><b>{numberFormat( (parseInt(report.order_store)-parseInt(report.order_basic)-parseInt(report.subsidi)) - (parseInt(report.spendings ? report.spendings : 0)+parseInt(report.virtual_spendings ? report.virtual_spendings : 0)) + parseInt(report.virtual_incomes ? report.virtual_incomes : 0) )}</b></td>
                                </>
                              ) }
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : currentPage === 'member_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <div className='row'>
                          <div className='col-sm-5'>
                            <h3 className="mb-0">Detail Report Mitra</h3>
                          </div>
                          <div className='col-sm-2'>
                            <input type='text' placeholder='Level Mitra' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                          </div>
                          <div className='col-sm-2'>
                            <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                          </div>
                          <div className='col-sm-2'>
                            <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                          </div>
                          <div className='col-sm-1'>
                            <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerOrderMember ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartMember && reportChartMember.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Mitra</th>
                              <th scope="col">Level</th>
                              <th scope="col">No.HP</th>
                              <th scope="col">Terdaftar</th>
                              <th scope="col">Pesanan</th>
                              <th scope="col">Pembelian Mitra</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Perkiraan HPP</th>
                              ) }
                              <th scope="col">Subsidi</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Margin</th>
                              ) }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartMember.map((u) => {
                                return(
                                  <tr key={u.username}>
                                    <td>
                                      (<b>{u.username}</b>) {u.full_name}
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem onClick={(e) => toggle(u.id)}>
                                            Ubah
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                      { u.instagram ? (
                                        <>
                                          <br/> IG {u.instagram}
                                        </>
                                      ) : null}
                                      <br/>
                                      {u.city}, {u.province}
                                      <br/> 
                                      {u.staff_id ? (
                                        <>
                                          CS {u.staff_id}
                                        </>
                                      ) : null }
                                    </td>
                                    <td>
                                      {u.group_name ? u.group_name : 'Customer'}
                                    </td>
                                    <td>
                                      <a href={`https://wa.me/`+u.phone1} target="_blank" className='btn-wa' style={{marginBottom: 5, display: 'inline-block'}}>{u.phone1}</a>
                                      <br/>
                                      {
                                        u.phone2 > 5 ? (<a href={`https://wa.me/`+u.phone2} target="_blank" className='btn-wa'>{u.phone2}</a>) : null
                                      }
                                      
                                    </td>
                                    <td>
                                      Terdaftar <b>{u.created_at}</b> 
                                      { u.first_order_value > 0 ? (
                                        <>
                                          <br/> Pembelian Awal <b>{numberFormat(u.first_order_value)}</b>
                                        </>
                                      ) : null}
                                      <br/>
                                      Pesanan Terakhir <b>{u.last_order_date ? u.last_order_date : '-'}</b>
                                      <br/>
                                      Status Terakhir <b>{u.last_followup ? u.last_followup : '-'}</b>
                                      <br/>
                                      Terakhir FollowUp <b>{u.last_followup_date ? u.last_followup_date : '-'}</b>
                                      <br/>
                                      Subyek Pesan <b>{u.last_followup_note ? u.last_followup_note : '-'}</b>
                                    </td>
                                    <td>{numberFormat(u.order_count)}</td>
                                    <td>+{numberFormat(u.store_price)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>-{numberFormat(u.basic_price)}</td>
                                    ) }
                                    <td>-{numberFormat(u.subsidi)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>{numberFormat(parseInt(u.store_price)-parseInt(u.basic_price)-parseInt(u.subsidi))}</td>
                                    ) }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>

                        {pagingMember ? (
                          <div class='col'>
                            <div onClick={loadChartMemberMore} class='load-more-btn'>
                              {loadMoreMember ? (
                                <center><Dots color='#444' /><br/></center>
                              ) : null}
                              SELANJUTNYA
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : currentPage == 'region_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <div className='row'>
                          <div className='col-sm-5'>
                            <h3 className="mb-0">Detail Report Kota</h3>
                          </div>
                          <div className='col-sm-2'>
                            <input type='text' placeholder='Level Mitra' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                          </div>
                          <div className='col-sm-2'>
                            <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                          </div>
                          <div className='col-sm-2'>
                            <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                          </div>
                          <div className='col-sm-1'>
                            <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerOrderCity ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartCity && reportChartCity.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Kota</th>
                              <th scope="col">Provinsi</th>
                              <th scope="col">Pesanan</th>
                              <th scope="col">Mitra</th>
                              <th scope="col">Omset Penjualan</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Perkiraan HPP</th>
                              ) }
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Margin</th>
                              ) }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartCity.map((u) => {
                                return(
                                  <tr key={u.city}>
                                    <td>{u.city}</td>
                                    <td>{u.province}</td>
                                    <td>{numberFormat(u.order_count)}</td>
                                    <td>{numberFormat(u.count_order_members)}</td>
                                    <td>+{numberFormat(u.store_price)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>-{numberFormat(u.basic_price)}</td>
                                    ) }
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>{numberFormat(parseInt(u.store_price)-parseInt(u.basic_price))}</td>
                                    ) }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>

                        {pagingCity ? (
                          <div class='col'>
                            <div onClick={loadChartCityMore} class='load-more-btn'>
                              {loadMoreCity ? (
                                <center><Dots color='#444' /><br/></center>
                              ) : null}
                              SELANJUTNYA
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : currentPage == 'product_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Detail Report Product</h3>
                      </div>
                      <div className="col">
                        <div className='row'>
                          <div className='col-sm-5'>
                            <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                          </div>
                          <div className='col-sm-5'>
                            <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                          </div>
                          <div className='col-sm-2'>
                            <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerOrderProduct ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartProduct && reportChartProduct.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Produk</th>
                              <th scope="col">Pesanan</th>
                              <th scope="col">Mitra</th>
                              <th scope="col">Omset Penjualan</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Perkiraan HPP</th>
                              ) }
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Margin</th>
                              ) }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartProduct.map((u) => {
                                return(
                                  <tr key={u.id}>
                                    <td>{u.id}</td>
                                    <td>[<b>{u.code}</b>] {u.title}</td>
                                    <td>{numberFormat(u.order_count)}</td>
                                    <td>{numberFormat(u.count_order_members)}</td>
                                    <td>+{numberFormat(u.store_price)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>-{numberFormat(u.basic_price)}</td>
                                    ) }
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>{numberFormat(parseInt(u.store_price)-parseInt(u.basic_price))}</td>
                                    ) }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>

                        {pagingProduct ? (
                          <div class='col'>
                            <div onClick={loadChartProductMore} class='load-more-btn'>
                              {loadMoreProduct ? (
                                <center><Dots color='#444' /><br/></center>
                              ) : null}
                              SELANJUTNYA
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : currentPage == 'expedition_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Detail Report Expedisi</h3>
                      </div>
                      <div className="col">
                        <div className='row'>
                          <div className='col-sm-5'>
                            <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                          </div>
                          <div className='col-sm-5'>
                            <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                          </div>
                          <div className='col-sm-2'>
                            <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerOrderExpedition ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartExpedition && reportChartExpedition.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Expedisi</th>
                              <th scope="col">Metode Pembayaran</th>
                              <th scope="col">Pesanan</th>
                              <th scope="col">Mitra</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Perkiraan HPP</th>
                              ) }
                              <th scope="col">Omset Penjualan</th>
                              { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <th scope="col">Margin</th>
                              ) }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartExpedition.map((u) => {
                                return(
                                  <tr key={u.expedition_name+'-'+u.payment_method}>
                                    <td>{u.expedition_name ? u.expedition_name.toUpperCase() : null}</td>
                                    <td>{u.payment_method}</td>
                                    <td>{numberFormat(u.order_count)}</td>
                                    <td>{numberFormat(u.count_order_members)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>{numberFormat(u.basic_price)}</td>
                                    ) }
                                    <td>{numberFormat(u.store_price)}</td>
                                    { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                      <td>{numberFormat(parseInt(u.store_price)-parseInt(u.basic_price))}</td>
                                    ) }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : currentPage == 'spending_report' ? (
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Detail Report Pengeluaran</h3>
                      </div>
                      <div className="col">
                        <div className='row'>
                          <div className='col-sm-5'>
                            <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                          </div>
                          <div className='col-sm-5'>
                            <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                          </div>
                          <div className='col-sm-2'>
                            <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>

                  {
                    spinnerSpending ? (
                      <center>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </center>
                    ) : reportChartSpending && reportChartSpending.length > 0 ? (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Kategori Id</th>
                              <th scope="col">Kategori Pengeluaran</th>
                              <th scope="col">Jumlah</th>
                              <th scope="col">Total Pengeluaran</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportChartSpending.map((u) => {
                                return(
                                  <tr key={u.id}>
                                    <td>{u.id}</td>
                                    <td>{u.name}</td>
                                    <td>{numberFormat(u.spending_count)}</td>
                                    <td>{numberFormat(u.spending_price)}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <div className='col col-md-12 input-field3'>
                        <span>Data Tidak Ditemukan</span>
                      </div>
                    )
                  }
                </Card>
              </Col>
            ) : null
          }
        </Row>
      </Container>
    </>
  );
};

export default ReportIndex;
