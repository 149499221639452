import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PurchaseShow = () => {
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();
  const {id} = useParams();

  const [data, setData] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: '', include: 'company,purchase_items' }};
    API.get(`purchases/${id}`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setPurchaseItems(data.purchase_items)
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData('');
  },[])

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>
                      FAKTUR {data.code}
                      <UncontrolledDropdown className='pull-right'>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem onClick={(e)=> history.push(`/admin/edit_purchase/${data.id}`)}>
                            Ubah
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-8'>
                    <b>PERUSAHAAN</b>
                    <br/>
                    <div>
                      {data.company ? data.company.full_name : ''}
                    </div>
                    <br/>
                    <b>KODE</b>
                    <br/>
                    <div>
                      {data.code}
                    </div>
                    <br/>
                    <b>KATEGORI</b>
                    <br/>
                    <div>
                      {data.category_name}
                    </div>
                    <br/>
                    <b>PEMBAYARAN KE</b>
                    <br/>
                    <div>
                      {data.to_account_name}
                    </div>
                    <br/>
                    <b>DIBUAT</b>
                    <br/>
                    <div>
                      {data.created_at}
                    </div>
                    <br/>
                    <b>TANGGAL TRANSAKSI</b>
                    <br/>
                    <div>
                      {data.transaction_date}
                    </div>

                    { data.description ? (
                      <>
                        <br/>
                        <b>KETERANGAN</b>
                        <br/>
                        <div>
                          {data.description}
                        </div>
                      </>
                    ) : null }
                  </div>
                  <div className='col-md-4'>
                    {
                      data.image_url ? (
                        <img src={data.image_url} className='img-factor'/>
                      ) : null
                    }
                  </div>
                </div>

                <br/>
                <Table className="align-items-center table-flush" responsive>
                  <tr>
                    <td width='25%'>
                      <b>ITEM</b>
                    </td>
                    <td width='25%'>
                      <b>QTY</b>
                    </td>
                    <td width='25%'>
                      <b>SATUAN HARGA</b>
                    </td>
                    <td width='25%'>
                      <b>SUBTOTAL</b>
                    </td>
                  </tr>
                  {
                    purchaseItems.map((item) => {
                      return(
                        <tr>
                          <td>
                            {item.title}
                          </td>
                          <td>
                            {item.quantity}
                          </td>
                          <td>
                            {numberFormat(item.bill_price)}
                          </td>
                          <td>
                            <b>{numberFormat(parseInt(item.bill_price)*parseInt(item.quantity))}</b>
                          </td>
                        </tr>
                      )
                    })
                  }

                  {
                    data.bill_total_price ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>TOTAL</b>
                        </td>
                        <td>
                          <b>{numberFormat(data.bill_price)}</b>
                        </td>
                      </tr>
                    ) : null
                  }

                  {
                    data.bill_shipping_price ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>ONGKOS KIRIM</b>
                        </td>
                        <td>
                          <b>{numberFormat(data.bill_shipping_price)}</b>
                        </td>
                      </tr>
                    ) : null
                  }

                  {
                    data.down_payment ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>DP</b>
                        </td>
                        <td>
                          <b>- {numberFormat(data.down_payment)}</b>
                        </td>
                      </tr>
                    ) : null
                  }

                  <tr>
                    <td></td>
                    <td></td>
                    <td className='text-right'>
                      <b>SISA PEMBAYARAN</b>
                    </td>
                    <td>
                      <b>{numberFormat(parseInt(data.bill_total_price)-parseInt(data.down_payment))}</b>
                    </td>
                  </tr>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PurchaseShow;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}