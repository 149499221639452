import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ProductIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [verified, setVerified] = useState(false);
  const [promo, setPromo] = useState(false);
  const [bestSeller, setBestSeller] = useState(false);
  const [weight, setWeight] = useState('');
  const [stock, setStock] = useState(0);
  const [basicPrice, setBasicPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [companyId, setCompanyId] = useState('');

  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();

  const [cashback, setCashback] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountCustomer, setDiscountCustomer] = useState(0);
  const [subsidi, setSubsidi] = useState(0);
  const [subsidiTxt, setSubsidiTxt] = useState('');

  const [merchants, setMerchants] = useState([]);
  const [brands, setBrands] = useState([]);
  const [promos, setPromos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [status, setStatus] = useState(true);
  const [byBrandId, setByBrandId] = useState('');
  const [byPromoId, setByPromoId] = useState('');
  const [byStatusPromo, setByStatusPromo] = useState('');

  const navCollection = [
    {'name':'Aktif', 'value': true},
    {'name':'Tidak Aktif', 'value': false}
  ]

  const collectPromo = [
    {'name':'Diskon Mitra', 'value': 'discount'},
    {'name':'Diskon Konsumen', 'value': 'discount_member'},
    {'name':'Subsidi', 'value': 'subsidi'},
    {'name':'Cashback', 'value': 'cashback'}
  ]

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setBrandId(dt.brand_id);
        setCategoryId(dt.category_id);
        setSubCategoryId(dt.sub_category_id);
        setMerchantId(dt.merchant_id);
        setCode(dt.code);
        setTitle(dt.title);
        setDescription(dt.description);
        setVerified(dt.verified);
        setPromo(dt.promo);
        setBestSeller(dt.bestseller);
        setWeight(dt.weight);
        setStock(dt.stock);
        setBasicPrice(dt.basic_price);
        setSellPrice(dt.sell_price);
        setCompanyId(dt.user_id);
        setImageSrc(dt.image_url);
        setImageFile('');
        setCashback(dt.cashback);
        setDiscount(dt.discount);
        setDiscountCustomer(dt.discount_customer);
        setSubsidi(dt.subsidi);
        setSubsidiTxt(dt.subsidi_txt);
        if(currentUser && currentUser.type === 'Adminmaster'){
          setCompanyLoad(dt.user_id);
        }
        setCategoryLoad(dt.category_id, dt.user_id);
      }else{
        setId('');
        setBrandId('');
        setCategoryId('');
        setSubCategoryId('');
        setMerchantId('');
        setCode('');
        setTitle('');
        setDescription('');
        setVerified(false);
        setPromo(false);
        setBestSeller(false);
        setWeight(0);
        setStock(0);
        setBasicPrice(0);
        setSellPrice(0);
        setCompanyId('');
        setImageSrc('');
        setImageFile('');
        setCashback(0);
        setDiscount(0);
        setDiscountCustomer(0);
        setSubsidi(0);
        setSubsidiTxt(0);
      }
    }else{
      setId('');
      setBrandId('');
      setCategoryId('');
      setSubCategoryId('');
      setMerchantId('');
      setCode('');
      setTitle('');
      setDescription('');
      setVerified(false);
      setPromo(false);
      setBestSeller(false);
      setWeight(0);
      setStock(0);
      setBasicPrice(0);
      setSellPrice(0);
      setCompanyId('');
      setImageSrc('');
      setImageFile('');
      setCashback(0);
      setDiscount(0);
      setDiscountCustomer(0);
      setSubsidi(0);
      setSubsidiTxt(0);
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('merchant_id', merchantId);
    fd.append('brand_id', brandId);
    fd.append('user_id', companyId);
    fd.append('category_id', categoryId);
    fd.append('sub_category_id', subCategoryId);
    fd.append('code', code);
    fd.append('title', title);
    fd.append('description', description);
    fd.append('verified', verified);
    fd.append('promo', promo);
    fd.append('bestseller', bestSeller);
    fd.append('weight', parseInt(weight));
    fd.append('stock', stock);
    fd.append('basic_price', parseInt(basicPrice));
    fd.append('sell_price', parseInt(sellPrice));
    fd.append('cashback', cashback);
    fd.append('discount', discount);
    fd.append('discount_customer', discountCustomer);
    fd.append('subsidi', subsidi);
    fd.append('subsidi_txt', subsidiTxt);
    fd.append('include', 'user');
    
    if(id){
      API.put(`products/${id}`, fd, config )
        .then(res => {
          const dt = res.data.data;
          const oldData = data;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          if (new_data !== -1){
            oldData[new_data] = dt
            setData(oldData);
          }

          setId('');
          setBrandId('');
          setCategoryId('');
          setSubCategoryId('');
          setMerchantId('');
          setCode('');
          setTitle('');
          setDescription('');
          setVerified(false);
          setPromo(false);
          setBestSeller(false);
          setWeight(0);
          setStock(0);
          setBasicPrice(0);
          setSellPrice(0);
          setCompanyId('');
          setImageSrc('');
          setImageFile('');
          setCashback(0);
          setDiscount(0);
          setDiscountCustomer(0);
          setSubsidi(0);
          setSubsidiTxt(0);
          // loadData(search, status, byBrandId, byPromoId, byStatusPromo);
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('products', fd, config )
        .then(res => {
          setId('');
          setBrandId('');
          setCategoryId('');
          setSubCategoryId('');
          setMerchantId('');
          setCode('');
          setTitle('');
          setDescription('');
          setVerified(false);
          setPromo(false);
          setBestSeller(false);
          setWeight(0);
          setStock(0);
          setBasicPrice(0);
          setSellPrice(0);
          setCompanyId('');
          setImageSrc('');
          setImageFile('');
          setCashback(0);
          setDiscount(0);
          setDiscountCustomer(0);
          setSubsidi(0);
          setSubsidiTxt(0);
          loadData(search, status, byBrandId, byPromoId, byStatusPromo);
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const loadData = (keyword, status, brand_id, promo_id, status_promo) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_status: status, by_brand_id: brand_id, by_promo_id: (promo_id ? promo_id : null), by_status_promo: status_promo, include: 'user' }};
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_user_type: 'Company', pagination: false  }};
    const config3 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_user_type: 'Merchant', pagination: false  }};

    API.get(`products`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })

    API.get(`users`, config3)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setMerchants(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { by_status: status, by_brand_id: byBrandId, by_promo_id: byPromoId, by_status_promo: byStatusPromo, page: offset_page, key: '', include: 'user'} };
    
    API.get(`products`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, status, byBrandId, byPromoId, byStatusPromo);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('', status, byBrandId, byPromoId, byStatusPromo);
    setLoadMoreButton(false);
  }

  const setCompanyLoad = (company_id) => {
    setCompanyId(company_id);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, by_company_id: company_id, pagination: false }};
    API.get(`brands`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data);
      })
    
    API.get(`categories`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data);
      })

    API.get(`promos`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromos(data);
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_company_id: company_id, by_user_type: 'Merchant', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setMerchants(data);
      })
  }

  const setCategoryLoad = (category_id, company_id) => {
    setCategoryId(category_id);
    let company = company_id ? company_id : companyId;

    if(currentUser && currentUser.type === 'Company'){
      company = currentUser.id
    }else if (currentUser && currentUser.type === 'Staff'){
      company = currentUser.company_id
    }
    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, by_company_id: company, by_parent_id: category_id, pagination: false }};    
    API.get(`categories`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setSubCategories(data);
      })
  }

  const changeVerified = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.put(`products/${id}/verified`, {status: ''}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData(search, status, byBrandId, byPromoId, byStatusPromo);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const statusSearch = (status) => {
    setStatus(status)
    loadData(search, status, byBrandId, byPromoId, byStatusPromo);
  }

  const searchBrand = (brand_id) => {
    setByBrandId(brand_id);
    setOffset(1);
    loadData(search, status, brand_id, byPromoId, byStatusPromo);
  }

  const searchPromo = (promo_id) => {
    setByPromoId(promo_id);
    setOffset(1);
    loadData(search, status, byPromoId, promo_id, byStatusPromo);
  }

  const searchStatusPromo = (status_promo) => {
    setByStatusPromo(status_promo);
    setOffset(1);
    loadData(search, status, byPromoId, byPromoId, status_promo);
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }

    setSearch('');
    loadData('', status, '', '', '');
  
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Produk</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <FormGroup className="form-img-select">
                <div class='avatar-user'>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: 120,
                      height: 120
                    }}
                  />
                </div>
              </FormGroup>

              <div className='row'>
                { currentUser && currentUser.type === 'Adminmaster' ? (
                  <div className='col-sm-12'>
                    <Label className='label-form-group'>Perusahaan</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={companyId} onChange={e => setCompanyLoad(e.target.value)}>
                        <option value=''>Pilih Perusahaan</option>
                        {
                          companies.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.full_name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>  
                ) : null }

                <div className='col-sm-12'>
                  <Label className='label-form-group'>Merchant</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={merchantId} onChange={e => setMerchantId(e.target.value)}>
                      <option value=''>Pilih Merchant</option>
                      {
                        merchants.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.full_name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>  

                <div className='col-6'>
                  <Label className='label-form-group'>SKU Produk</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-flag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="SKU Produk"
                      value={code} onChange={e => setCode(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Merek</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-archive" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={brandId} onChange={e => setBrandId(e.target.value)}>
                      <option value=''>Pilih Merek</option>
                      {
                        brands.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Pilih Kategori</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-folder" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={categoryId} onChange={e => setCategoryLoad(e.target.value)}>
                      <option value=''>Pilih Kategori</option>
                      {
                        categories.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Pilih Sub Kategori</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-folder" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={subCategoryId} onChange={e => setSubCategoryId(e.target.value)}>
                      <option value=''>Pilih Sub Kategori</option>
                      { subCategories ? (
                        subCategories.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      ) : null }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Nama Produk</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-bookmark" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nama Produk"
                      value={title} onChange={e => setTitle(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Deskripsi Produk</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      type="textarea"
                      placeholder="Deskripsi Produk"
                      value={description} onChange={e => setDescription(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Berat (gram)</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        Gr
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Berat"
                      value={weight} onChange={e => setWeight(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Stok Produk</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-tasks" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Stok Produk"
                      value={stock} onChange={e => setStock(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Harga Modal (HPP) Utama</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        RP
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Harga Modal"
                      value={basicPrice} onChange={e => setBasicPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Harga Jual Konsumen Utama</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        RP
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Harga Jual Konsumen"
                      value={sellPrice} onChange={e => setSellPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Diskon Mitra</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        %
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Diskon Produk"
                      value={discount} onChange={e => setDiscount(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Diskon Konsumen</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        %
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Diskon Produk"
                      value={discountCustomer} onChange={e => setDiscountCustomer(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Keterangan Subsidi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-tags" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Keterangan Subsidi Produk"
                      value={subsidiTxt} onChange={e => setSubsidiTxt(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Nilai Subsidi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Subsidi Produk"
                      value={subsidi} onChange={e => setSubsidi(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>Cashback</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Cashback Produk"
                      value={cashback} onChange={e => setCashback(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={promo} onChange={e => setPromo(!promo)}/> Promo
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-sm-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={bestSeller} onChange={e => setBestSeller(!bestSeller)}/> Bestseller
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  { (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' ? (
                    <>
                      <div className='col-sm-2'>
                        <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                      </div>
                      <div className="col-sm-2">
                        <select className="form-control" value={byBrandId} onChange={e => (searchBrand(e.target.value))} >
                          <option value=''>Semua Merek</option>
                          {
                            brands.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className="col-sm-2">
                        <select className="form-control" value={byPromoId} onChange={e => (searchPromo(e.target.value))} >
                          <option value=''>Semua Promo</option>
                          {
                            promos.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.title}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className="col-sm-2">
                        <select className="form-control" value={byStatusPromo} onChange={e => (searchStatusPromo(e.target.value))} >
                          <option value=''>Semua Status Promo</option>
                          {
                            collectPromo.map((c) => {
                              return (
                                <option key={c.value} value={c.value}>{c.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className='col-sm-2'>
                        <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                      </div>
                    </>
                  ) : (
                    <div className='col-sm-10'>
                      <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                    </div>
                  )}
                </div>
                <div className='row' style={{marginTop: 20}}>
                  <div className='col-sm-12'>
                    <div className='tab-nav back-white'>
                      <ul className='margin-none'>
                        {navCollection.map((nav, index) => {
                          return (
                            <li key={index+=1} className={nav.value === status ? 'active' : null } onClick={() => { statusSearch(nav.value)} }>
                              {nav.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Nama Produk</th>
                    <th scope="col">Label</th>
                    <th scope="col">Stok</th>
                    <th scope="col">Merek</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Poin</th>
                    { (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || (currentUser.type === 'Staff' && currentUser.role_access === 'finance') || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Harga HPP</th>
                    ) : null }
                    <th scope="col">Harga Konsumen</th>
                    <th scope="col">Berat</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="12" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem href={`/admin/product_edit/${u.id}`}>
                                      Pengaturan
                                    </DropdownItem>
                                    {currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                                      <DropdownItem onClick={(e) => toggle(u.id)}>
                                        Simple Pengaturan
                                      </DropdownItem>
                                    ) : null }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status_verified(u.verified)}>
                                  {u.verified ? (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  ) : (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  )}
                                </div>
                              </td>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a className="avatar rounded-circle mr-3" href="#" onClick={(e) => e.preventDefault()}>
                                    <img alt="..." src={u.image_url} />
                                  </a>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {u.title}
                                      <br/>
                                      <small>{u.code}</small>
                                      {
                                        currentUser && currentUser.type === 'Adminmaster' && u.user ? (
                                          <>
                                            <br/>
                                            <small>{u.user.full_name}</small>
                                          </>
                                        ) : null
                                      }
                                      { u.staff_commision > 0 && (currentUser && currentUser.type !== 'Merchant') ? (
                                        <>
                                          <br/>
                                          <small>Komisi Closing CS <b>{currencyFormat(u.staff_commision)}</b></small>
                                        </>
                                        ) : null
                                      }

                                    </span>
                                  </Media>
                                </Media>  
                              </th>
                              <td>
                                {u.promo ? (
                                  <>
                                    <div style={styles.status(u.promo)}>
                                      Label Promo
                                    </div>
                                  </>
                                ) : ''}
                                
                                {u.bestseller ? (
                                  <>
                                    <br/>
                                    <div style={styles.status(u.bestseller)}>
                                      Label Bestseller
                                    </div>
                                  </>
                                ) : ''}

                                { u.staff_commision > 0 && (currentUser && currentUser.type !== 'Merchant') ? (
                                  <>
                                    { u.cashback > 0 ? (
                                      <>
                                        <br/>
                                        <small>Cashback <b>{currencyFormat(u.cashback)}</b></small>
                                      </>
                                      ) : null
                                    }
                                    { u.subsidi > 0 ? (
                                      <>
                                        <br/>
                                        <small>Potongan Ongkir <b>{currencyFormat(u.subsidi)}</b></small>
                                      </>
                                      ) : null
                                    }
                                  
                                    { u.discount > 0 ? (
                                      <>
                                        <br/>
                                        <small>Discount Mitra <b>{u.discount}%</b></small>
                                      </>
                                      ) : null
                                    }
                                    { u.discount_customer > 0 ? (
                                      <>
                                        <br/>
                                        <small>Discount Customer <b>{u.discount_customer}%</b></small>
                                      </>
                                      ) : null
                                    }
                                  </>
                                ) : null }
                              </td>
                              <td>
                                {u.stock}
                              </td>
                              <td>
                                {u.brand_name}
                              </td>
                              <td>
                                {u.category_name}
                                <br/>
                                {u.sub_category_name}
                              </td>
                              <td>
                                {u.point}
                              </td>
                              { (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || (currentUser.type === 'Staff' && currentUser.role_access === 'finance') || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' ? (
                                <td>
                                  {currencyFormat(u.basic_price)}
                                </td>
                              ) : null }
                              <td>
                                {currencyFormat(u.sell_price)}
                              </td>
                              <td>
                                {u.weight} gr
                              </td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
                </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProductIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : 'red',
    paddingBottom: 2,
    borderRadius: 5,
  }),
  status_verified: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}