import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PurchaseNew = () => {
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [id, setId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [status, setStatus] = useState('pending');
  const [description, setDescription] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountId, setAccountId] = useState('');
  const [toAccountId, setToAccountId] = useState('');
  const [toAccountName, setToAccountName] = useState('');
  const [billPrice, setBillPrice] = useState(0);
  const [billShippingPrice, setBillShippingPrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [purchaseItems, setPurchaseItems] = useState([]);
  const [paymentNumbers, setPaymentNumbers] = useState([]);

  const [titleItem, setTitleItem] = useState('');
  const [quantityItem, setQuantityItem] = useState('');
  const [billPriceItem, setBillPriceItem] = useState('');
  const [billShippingPriceItem, setBillShippingPriceItem] = useState('');
  const [brandId, setBrandId] = useState('');
  const [productId, setProductId] = useState('');
  const [productVariantId, setProductVariantId] = useState('');

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Proses', 'value': 'process'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  const categories = [
    {'name':'Pengadaan Produk', 'value': 'product_price'},
    {'name':'Pengadaan Packaging', 'value': 'packaging_price'},
    {'name':'Bayar Ongkir', 'value':'shipping_price'},
    {'name':'Lain-Lain', 'value':'other'}
  ]


  const loadData = () => {    
    if(id){
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: '', include: 'company,purchase_items' }};
      API.get(`purchases/${id}`, config)
        .then(res => {
          const data = res.data.data;
          console.log(res.data)
          setData(data)
          setStatus(data.status);
          setSpinner(false);

          setCode(data.code);
          setTitle(data.title);
          setCategory(data.category);
          setStatus(data.status_payment);
          setDescription(data.description);
          setTransactionDate(data.transaction_date);
          setAccountId(data.account_id);
          setAccountName(data.account_name);
          setToAccountName(data.to_account_name);
          setBillPrice(data.bill_price);
          setBillShippingPrice(data.bill_shipping_price);
          setDownPayment(data.down_payment);
          setCompanyLoad(data.user_id);
          setImageSrc(data.image_url);
          setImageFile('');
          setPurchaseItems(data.purchase_items)
        })
    }

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
  }

  const setCompanyLoad = (company_id) => {
    setCompanyId(company_id);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, by_status: true, pagination: false }};
    API.get(`payment_numbers`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
  }

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('code', code);
    fd.append('title', title);
    fd.append('category', category);
    fd.append('status_payment', status);
    fd.append('description', description);
    fd.append('transaction_date', transactionDate);
    fd.append('account_name', accountName);
    fd.append('account_id', accountId);
    fd.append('to_account_id', toAccountId);
    fd.append('to_account_name', toAccountName);
    fd.append('bill_price', billPrice);
    fd.append('bill_shipping_price', billShippingPrice);
    fd.append('down_payment', downPayment);
    fd.append('user_id', companyId);
    fd.append('include', 'company,purchase_items');

    if(id){
      API.put(`purchases/${id}`, fd, config )
        .then(res => {
          const dt = res.data.data;
          setData(dt);
          setId(dt.id)
          setCode(dt.code);
          setTitle(dt.title);
          setCategory(dt.category);
          setStatus(dt.status_payment);
          setDescription(dt.description);
          setTransactionDate(dt.transaction_date);
          setAccountId(dt.account_id);
          setAccountName(dt.account_name);
          setToAccountId(dt.to_account_id);
          setToAccountName(dt.to_account_name);
          setBillPrice(dt.bill_price);
          setBillShippingPrice(dt.bill_shipping_price);
          setDownPayment(dt.down_payment);
          if(currentUser && currentUser.type === 'Adminmaster'){
            setCompanyLoad(dt.user_id);
          }
          setCompanyId(dt.user_id);
          setImageSrc(dt.image_url);
          setImageFile('');

          setSpinnerSave(false);
          loadData('');

          setPurchaseItems(data.purchase_items)
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post(`purchases`, fd, config )
        .then(res => {
          const dt = res.data.data;
          setData(dt);
          setId(dt.id);
          setCode(dt.code);
          setTitle(dt.title);
          setCategory(dt.category);
          setStatus(dt.status_payment);
          setDescription(dt.description);
          setTransactionDate(dt.transaction_date);
          setAccountId(dt.account_id);
          setToAccountId(dt.to_account_id);
          setAccountName(dt.account_name);
          setToAccountName(dt.to_account_name);
          setBillPrice(dt.bill_price);
          setBillShippingPrice(dt.bill_shipping_price);
          setDownPayment(dt.down_payment);
          if(currentUser && currentUser.type === 'Adminmaster'){
            setCompanyLoad(dt.user_id);
          }
          setCompanyId(dt.user_id);
          setImageSrc(dt.image_url);
          setImageFile('');

          setSpinnerSave(false);
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }

  }

  const actPostItem = () => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('purchase_id', id);
    fd.append('title', titleItem);
    fd.append('quantity', quantityItem);
    fd.append('bill_price', billPriceItem);
    fd.append('bill_shipping_price', billShippingPriceItem);
    fd.append('brand_id', brandId);
    fd.append('product_id', productId);
    fd.append('product_variant_id', productVariantId);
    fd.append('include', 'company,purchase_items');

    API.post(`purchase_items`, fd, config )
      .then(res => {
        const dt = res.data.data;
        setTitleItem('');
        setQuantityItem('');
        setBillPriceItem('');
        setBillShippingPriceItem('');
        setBrandId('');
        setProductId('');
        setProductVariantId('');
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })

  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    API.delete(`purchase_items/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData('');

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }

  },[])

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>
                      FAKTUR {data.code}
                    </h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='row'>
                      <div className='col-12'>
                        <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                          {errorMessage}
                        </Alert>
                      </div>
                      { currentUser && currentUser.type === 'Adminmaster' ? (
                        <>
                          <div className='col-6'>
                            <Label className='label-form-group'>Perusahaan</Label>
                            <InputGroup className="input-group-alternative mb-20">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-building" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={companyId} onChange={e => setCompanyLoad(e.target.value)}>
                                <option value=''>Pilih Perusahaan</option>
                                {
                                  companies.map((c) => {
                                    return (
                                      <option key={c.id} value={c.id}>{c.full_name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                          <div className='col-6'>
                            <Label className='label-form-group'>Kategori</Label>
                            <InputGroup className="input-group-alternative mb-20">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-list" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={category} onChange={e => setCategory(e.target.value)}>
                                <option value=''>Pilih Kategori</option>
                                {
                                  categories.map((c) => {
                                    return (
                                      <option key={c.id} value={c.id}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                        </>
                      ) : (
                        <div className='col-12'>
                          <Label className='label-form-group'>Kategori</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-list" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="select" value={category} onChange={e => setCategory(e.target.value)}>
                              <option value=''>Pilih Kategori</option>
                              {
                                categories.map((c) => {
                                  return (
                                    <option key={c.value} value={c.value}>{c.name}</option>
                                  )
                                })
                              }
                            </Input>
                          </InputGroup>
                        </div>
                      ) }
                      <div className='col-6'>
                        <Label className='label-form-group'>Kode Faktur</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-flag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Kode Faktur"
                            value={code} onChange={e => setCode(e.target.value)}
                          />
                        </InputGroup>
                      </div>  
                      <div className='col-6'>
                        <Label className='label-form-group'>Status</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-star" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" value={status} onChange={e => setStatus(e.target.value)}>
                            <option value=''>Pilih Status</option>
                            {
                              statusCollectionPayment.map((c) => {
                                return (
                                  <option key={c.value} value={c.value}>{c.name}</option>
                                )
                              })
                            }
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Judul</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-coins" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Judul"
                            value={title} onChange={e => setTitle(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Keterangan</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            type="textarea"
                            placeholder="Keterangan"
                            value={description} onChange={e => setDescription(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Nilai Tagihan</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              RP
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            placeholder="Nilai Tagihan"
                            value={billPrice} onChange={e => setBillPrice(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Nilai Ongkir</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              RP
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            placeholder="Nilai Ongkir"
                            value={billShippingPrice} onChange={e => setBillShippingPrice(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Nilai DP</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              RP
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            placeholder="Nilai DP"
                            value={downPayment} onChange={e => setDownPayment(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Tanggal Transaksi</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <DatePicker id= "example-datepicker" value={transactionDate} onChange={e => setTransactionDate(e)} />
                        </InputGroup>
                      </div>

                      <div className='col-6'>
                        <Label className='label-form-group'>Dibayar Dari</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-credit-card" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" value={accountName} onChange={e => setAccountName(e.target.value)}>
                            <option value=''>Pilih No.Rekening</option>
                            {
                              paymentNumbers.map((c) => {
                                return (
                                  <option key={c.bank+'-'+c.number} value={c.bank+'-'+c.number}>{c.bank+'-'+c.number}</option>
                                )
                              })
                            }
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Tujuan Pembayaran</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-credit-card" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tujuan Rekening Pembayaran"
                            value={toAccountName} onChange={e => setToAccountName(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class='avatar-user avatar-user-sidebar' style={{marginTop: 25}}>
                      <ImageUpload
                        handleImageSelect={handleImageSelect}
                        imageSrc={imageSrc}
                        setImageSrc={setImageSrc}
                        style={{
                          width: 120,
                          height: 120
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <br/>
                    { spinnerSave ? (
                      <Button color="primary" style={{background: (currentCompany.company_color3)}}>
                        <Dots color='#fff' />
                      </Button>
                    ) : (
                      <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
                    )}
                  </div>
                </div>

                { id ? (
                  <>
                    <br/>
                    <Table className="align-items-center table-flush" responsive>
                      <tr>
                        <td>
                          <b>ITEM</b>
                        </td>
                        <td>
                          <b>QTY</b>
                        </td>
                        <td>
                          <b>SATUAN HARGA</b>
                        </td>
                        <td>
                          <b>SUBTOTAL</b>
                        </td>
                      </tr>
                      {
                        purchaseItems.map((item) => {
                          return(
                            <tr>
                              <td>
                                <span className='pointer' onClick={(e) => deletePost(item.id)}>
                                  <i className='fa fa-trash'></i>
                                </span> {item.title}
                              </td>
                              <td>
                                {item.quantity}
                              </td>
                              <td>
                                {numberFormat(item.bill_price)}
                              </td>
                              <td>
                                <b>{numberFormat(parseInt(item.bill_price)*parseInt(item.quantity))}</b>
                              </td>
                            </tr>
                          )
                        })
                      }

                      <tr>
                        <td>
                          <Input
                            placeholder="Item"
                            value={titleItem} onChange={e => setTitleItem(e.target.value)}
                          />
                        </td>
                        <td>
                          <Input
                            type='number'
                            placeholder="Qty"
                            value={quantityItem} onChange={e => setQuantityItem(e.target.value)}
                          />
                        </td>
                        <td>
                          <Input
                            type='number'
                            placeholder="Satuan Harga"
                            value={billPriceItem} onChange={e => setBillPriceItem(e.target.value)}
                          />
                        </td>
                        <td>
                          <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPostItem()} className='pull-right full-width'>
                            <i class="fas fa-plus"></i>
                          </Button>
                        </td>
                      </tr>

                      {
                        data.bill_total_price ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>TOTAL</b>
                            </td>
                            <td>
                              <b>{numberFormat(data.bill_price)}</b>
                            </td>
                          </tr>
                        ) : null
                      }

                      {
                        data.bill_shipping_price ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>ONGKOS KIRIM</b>
                            </td>
                            <td>
                              <b>{numberFormat(data.bill_shipping_price)}</b>
                            </td>
                          </tr>
                        ) : null
                      }

                      {
                        data.down_payment ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>DP</b>
                            </td>
                            <td>
                              <b>- {numberFormat(data.down_payment)}</b>
                            </td>
                          </tr>
                        ) : null
                      }

                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>SISA PEMBAYARAN</b>
                        </td>
                        <td>
                          <b>{numberFormat(parseInt(data.bill_total_price)-parseInt(data.down_payment))}</b>
                        </td>
                      </tr>
                    </Table>
                  </>
                ) : null }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PurchaseNew;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}