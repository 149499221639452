import React, { useEffect, useState } from 'react';
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import ReactSession from '../ReactSession';

import routes from "routes.js";

import Index from "views/Index.js";
import CompanyIndex from "views/companies/index.js";
import MerchantsIndex from "views/merchants/index.js";
import StaffIndex from "views/staffs/index.js";
import MemberIndex from "views/members/index.js";
import GroupUserIndex from "views/group_users/index.js";
import BrandIndex from "views/brands/index.js";
import CategoryIndex from "views/categories/index.js";
import Expeditions from "views/expeditions/index.js";
import PaymentNumbers from "views/payment_numbers/index.js";
import AccountIndex from "views/accounts/index.js";
import ProductIndex from "views/products/index.js";
import OrderIndex from "views/orders/index.js";
import SpendingCategoryIndex from "views/spending_categories/index.js";
import SpendingIndex from "views/spendings/index.js";
import ReportIndexDaily from "views/reports/index.js";
import ReportIndexMonthly from "views/reports/index.js";
import ReportIndexMember from "views/reports/index.js";
import ReportIndexRegion from "views/reports/index.js";
import ReportIndexProduct from "views/reports/index.js";
import ReportIndexExpedition from "views/reports/index.js";
import ReportIndexSpending from "views/reports/index.js";
import ShopIndex from "views/shops/index.js";
import TrackShippingIndex from "views/shops/track_shipping.js";
import CheckShippingIndex from "views/shops/check_shipping.js";
import MarketingKitIndex from "views/brands/content.js";
import RewardsIndex from "views/rewards/index.js";
import BlogsIndex from "views/blogs/index.js";
import PaymentsIndex from "views/payments/index.js";
import VirtualsIndex from "views/virtuals/index.js";
import BillingsIndex from "views/billings/index.js";
import WaSettingsIndex from "views/wa_settings/index.js";
import LandingPressesIndex from "views/landing_presses/index.js";
import RequestLpIndex from "views/request_landing_presses/index.js";
import ReportMembers from "views/report_members/index.js";
import VouchersIndex from "views/vouchers/index.js";
import PromoIndex from "views/promos/index.js";
import PurchaseIndex from "views/purchases/index.js";
import ScanCode from "views/scan_code/index.js";
import Icons from "views/examples/Icons.js";
import { Helmet } from 'react-helmet';

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const currentSidebar = ReactSession.get("current_sidebar");
  const [currentLogo, setCurrentLogo] = useState('');

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  };

  useEffect(() => {    
    if(token === undefined){
      history.push("/auth/login")
    }
    
    setCurrentLogo(currentCompany ? currentCompany.company_logo_url : '')
  },[])

  var access_pages_admin = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "#reports",
      name: "Report",
      icon: "ni ni-sound-wave",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/report_members",
          name: "Penyebaran Domisili Mitra",
          icon: "ni ni-square-pin",
          component: ReportMembers,
          layout: "/admin",
        },
        {
          path: "/daily_report",
          name: "Report Bulanan",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexDaily,
          layout: "/admin",
        },
        {
          path: "/monthly_report",
          name: "Report Tahunan",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMonthly,
          layout: "/admin",
        },
        {
          path: "/member_report",
          name: "Report Mitra",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMember,
          layout: "/admin",
        },
        {
          path: "/region_report",
          name: "Report Daerah",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexRegion,
          layout: "/admin",
        },
        {
          path: "/product_report",
          name: "Report Produk",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexProduct,
          layout: "/admin",
        },
        // {
        //   path: "/expedition_report",
        //   name: "Report Expedisi",
        //   icon: "ni ni-chart-bar-32",
        //   component: ReportIndexExpedition,
        //   layout: "/admin",
        // }
      ]
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-cart",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/companies",
      name: "Data Pengguna",
      icon: "ni ni-circle-08",
      component: CompanyIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/companies",
          name: "Kelola Perusahaan",
          icon: "ni ni-building",
          component: CompanyIndex,
          layout: "/admin",
        },
        {
          path: "/staffs",
          name: "Kelola Staff",
          icon: "ni ni-user-run",
          component: StaffIndex,
          layout: "/admin",
        },
        {
          path: "/members",
          name: "Kelola Mitra",
          icon: "ni ni-circle-08",
          component: MemberIndex,
          layout: "/admin",
        },
      ]
    },
    {
      path: "#",
      name: "Data Produk",
      icon: "ni ni-bag-17",
      component: ProductIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/brands",
          name: "Kelola Merek",
          icon: "ni ni-trophy",
          component: BrandIndex,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Kelola Kategori",
          icon: "ni ni-folder-17",
          component: CategoryIndex,
          layout: "/admin",
        },
        {
          path: "/products",
          name: "Kelola Produk",
          icon: "ni ni-bag-17",
          component: ProductIndex,
          layout: "/admin"
        },
        {
          path: "/vouchers",
          name: "Kelola Voucher",
          icon: "ni ni-diamond",
          component: VouchersIndex,
          layout: "/admin"
        },
        {
          path: "/promos",
          name: "Kelola Promo",
          icon: "ni ni-bell-55",
          component: PromoIndex,
          layout: "/admin"
        }
      ]
    },
    {
      path: "#spendings",
      name: "Data Pengeluaran",
      icon: "fas fa-coins",
      component: SpendingIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/spending_report",
          name: "Report Pengeluaran",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexSpending,
          layout: "/admin",
        },
        {
          path: "/spending_categories",
          name: "Kategori Pengeluaran",
          icon: "ni ni-bullet-list-67",
          component: SpendingCategoryIndex,
          layout: "/admin",
        },
        {
          path: "/spendings",
          name: "Kelola Pengeluaran",
          icon: "fas fa-coins",
          component: SpendingIndex,
          layout: "/admin",
        },
        {
          path: "/payments",
          name: "Pengajuan Pencairan Saldo",
          icon: "fas fa-coins",
          component: PaymentsIndex,
          layout: "/admin",
        },
        {
          path: "/purchases",
          name: "Purchasing",
          icon: "fas fa-coins",
          component: PurchaseIndex,
          layout: "/admin",
        },
      ]
    },
    {
      path: "/purchases",
      name: "Purchasing",
      icon: "ni ni-credit-card",
      component: PurchaseIndex,
      layout: "/admin",
    },
    {
      path: "/billings",
      name: "Billing",
      icon: "ni ni-credit-card",
      component: BillingsIndex,
      layout: "/admin",
    },
    {
      path: "#",
      name: "Pengaturan",
      icon: "fa fa-wrench",
      component: null,
      layout: null,
      child_open: false,
      childs: [
        {
          path: "/staffs",
          name: "Kelola Staff",
          icon: "ni ni-user-run",
          component: StaffIndex,
          layout: "/admin",
        },
        {
          path: "/group_users",
          name: "Level Mitra",
          icon: "ni ni-spaceship",
          component: GroupUserIndex,
          layout: "/admin",
        },
        {
          path: "/virtuals",
          name: "Kelola Saldo & Poin",
          icon: "fas fa-coins",
          component: VirtualsIndex,
          layout: "/admin",
        },
        {
          path: "/blogs",
          name: "Kelola Blog",
          icon: "ni ni-tv-2",
          component: BlogsIndex,
          layout: "/admin",
        },
        {
          path: "/rewards",
          name: "Kelola Reward",
          icon: "ni ni-trophy",
          component: RewardsIndex,
          layout: "/admin",
        },
        {
          path: "/expeditions",
          name: "Expedisi Pengiriman",
          icon: "ni ni-delivery-fast",
          component: Expeditions,
          layout: "/admin",
        },
        {
          path: "/accounts",
          name: "Kelola Akun",
          icon: "ni ni-credit-card",
          component: AccountIndex,
          layout: "/admin",
        },
        {
          path: "/payment_numbers",
          name: "Nomor Rekening",
          icon: "ni ni-credit-card",
          component: PaymentNumbers,
          layout: "/admin",
        },
        {
          path: "/wa_settings",
          name: "WA Setting",
          icon: "ni ni-chat-round",
          component: WaSettingsIndex,
          layout: "/admin",
        },
        {
          path: "/landing_presses",
          name: "Landing Press",
          icon: "ni ni-tv-2",
          component: LandingPressesIndex,
          layout: "/admin",
        },
        {
          path: "/request_landing_presses",
          name: "Request Landing Page",
          icon: "ni ni-tv-2",
          component: RequestLpIndex,
          layout: "/admin",
        }
      ]
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/scan-code",
          name: "Scan QR Code",
          icon: "ni ni-mobile-button",
          component: ScanCode,
          layout: "/admin",
        },
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        },
        {
          path: "/marketingkit",
          name: "Marketing Kit",
          icon: "ni ni-album-2",
          component: MarketingKitIndex,
          layout: "/admin",
        },
      ]
    }
  ];

  var access_pages_company = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "#reports",
      name: "Report",
      icon: "ni ni-sound-wave",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/report_members",
          name: "Penyebaran Domisili Mitra",
          icon: "ni ni-square-pin",
          component: ReportMembers,
          layout: "/admin",
        },
        {
          path: "/daily_report",
          name: "Report Harian",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexDaily,
          layout: "/admin",
        },
        {
          path: "/monthly_report",
          name: "Report Bulanan",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMonthly,
          layout: "/admin",
        },
        {
          path: "/member_report",
          name: "Report Mitra",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMember,
          layout: "/admin",
        },
        {
          path: "/region_report",
          name: "Report Daerah",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexRegion,
          layout: "/admin",
        },
        {
          path: "/product_report",
          name: "Report Produk",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexProduct,
          layout: "/admin",
        },
        // {
        //   path: "/expedition_report",
        //   name: "Report Expedisi",
        //   icon: "ni ni-chart-bar-32",
        //   component: ReportIndexExpedition,
        //   layout: "/admin",
        // }
      ]
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-cart",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/members",
      name: "Kelola Mitra",
      icon: "ni ni-circle-08",
      component: MemberIndex,
      layout: "/admin",
    },
    {
      path: "#products",
      name: "Data Produk",
      icon: "ni ni-bag-17",
      component: ProductIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/brands",
          name: "Kelola Merek",
          icon: "ni ni-trophy",
          component: BrandIndex,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Kelola Kategori",
          icon: "ni ni-folder-17",
          component: CategoryIndex,
          layout: "/admin",
        },
        {
          path: "/products",
          name: "Kelola Produk",
          icon: "ni ni-bag-17",
          component: ProductIndex,
          layout: "/admin"
        },
        {
          path: "/vouchers",
          name: "Kelola Voucher",
          icon: "ni ni-diamond",
          component: VouchersIndex,
          layout: "/admin"
        },
        {
          path: "/promos",
          name: "Kelola Promo",
          icon: "ni ni-bell-55",
          component: PromoIndex,
          layout: "/admin"
        }
      ]
    },
    {
      path: "#spendings",
      name: "Data Pengeluaran",
      icon: "fas fa-coins",
      component: SpendingIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/spending_report",
          name: "Report Pengeluaran",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexSpending,
          layout: "/admin",
        },
        {
          path: "/spending_categories",
          name: "Kategori Pengeluaran",
          icon: "ni ni-bullet-list-67",
          component: SpendingCategoryIndex,
          layout: "/admin",
        },
        {
          path: "/spendings",
          name: "Kelola Pengeluaran",
          icon: "fas fa-coins",
          component: SpendingIndex,
          layout: "/admin",
        },
        {
          path: "/payments",
          name: "Pengajuan Pencairan Saldo",
          icon: "fas fa-coins",
          component: PaymentsIndex,
          layout: "/admin",
        },
        {
          path: "/purchases",
          name: "Purchasing",
          icon: "fas fa-coins",
          component: PurchaseIndex,
          layout: "/admin",
        },
      ]
    },
    {
      path: "/billings",
      name: "Billing",
      icon: "ni ni-credit-card",
      component: BillingsIndex,
      layout: "/admin",
    },
    {
      path: "#config",
      name: "Pengaturan",
      icon: "fa fa-wrench",
      component: null,
      layout: null,
      child_open: false,
      childs: [
        {
          path: "/merchants",
          name: "Kelola Merchant",
          icon: "ni ni-badge",
          component: MerchantsIndex,
          layout: "/admin",
        },
        {
          path: "/staffs",
          name: "Kelola Staff",
          icon: "ni ni-user-run",
          component: StaffIndex,
          layout: "/admin",
        },
        {
          path: "/group_users",
          name: "Level Mitra",
          icon: "ni ni-spaceship",
          component: GroupUserIndex,
          layout: "/admin",
        },
        {
          path: "/virtuals",
          name: "Kelola Saldo & Poin",
          icon: "fas fa-coins",
          component: VirtualsIndex,
          layout: "/admin",
        },
        {
          path: "/blogs",
          name: "Kelola Blog",
          icon: "ni ni-tv-2",
          component: BlogsIndex,
          layout: "/admin",
        },
        {
          path: "/rewards",
          name: "Kelola Reward",
          icon: "ni ni-trophy",
          component: RewardsIndex,
          layout: "/admin",
        },
        {
          path: "/expeditions",
          name: "Expedisi Pengiriman",
          icon: "ni ni-delivery-fast",
          component: Expeditions,
          layout: "/admin",
        },
        {
          path: "/accounts",
          name: "Kelola Akun",
          icon: "ni ni-credit-card",
          component: AccountIndex,
          layout: "/admin",
        },
        {
          path: "/payment_numbers",
          name: "Nomor Rekening",
          icon: "ni ni-credit-card",
          component: PaymentNumbers,
          layout: "/admin",
        },
        {
          path: "/wa_settings",
          name: "WA Setting",
          icon: "ni ni-chat-round",
          component: WaSettingsIndex,
          layout: "/admin",
        },
        {
          path: "/landing_presses",
          name: "Landing Press",
          icon: "ni ni-tv-2",
          component: LandingPressesIndex,
          layout: "/admin",
        },
        {
          path: "/request_landing_presses",
          name: "Request Landing Page",
          icon: "ni ni-tv-2",
          component: RequestLpIndex,
          layout: "/admin",
        }
      ]
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/scan-code",
          name: "Scan QR Code",
          icon: "ni ni-mobile-button",
          component: ScanCode,
          layout: "/admin",
        },
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        },
        {
          path: "/marketingkit",
          name: "Marketing Kit",
          icon: "ni ni-album-2",
          component: MarketingKitIndex,
          layout: "/admin",
        },
      ]
    }
  ];

  var access_pages_company_admin = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "/shops",
      name: "Keranjang Belanja",
      icon: "ni ni-cart",
      component: ShopIndex,
      layout: "/admin",
    },
    {
      path: "#reports",
      name: "Report",
      icon: "ni ni-sound-wave",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/report_members",
          name: "Penyebaran Domisili Mitra",
          icon: "ni ni-square-pin",
          component: ReportMembers,
          layout: "/admin",
        },
        {
          path: "/daily_report",
          name: "Report Harian",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexDaily,
          layout: "/admin",
        },
        {
          path: "/monthly_report",
          name: "Report Bulanan",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMonthly,
          layout: "/admin",
        },
        {
          path: "/member_report",
          name: "Report Mitra",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMember,
          layout: "/admin",
        },
        {
          path: "/region_report",
          name: "Report Daerah",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexRegion,
          layout: "/admin",
        },
        {
          path: "/product_report",
          name: "Report Produk",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexProduct,
          layout: "/admin",
        },
        // {
        //   path: "/expedition_report",
        //   name: "Report Expedisi",
        //   icon: "ni ni-chart-bar-32",
        //   component: ReportIndexExpedition,
        //   layout: "/admin",
        // }
      ]
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-cart",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/members",
      name: "Kelola Mitra",
      icon: "ni ni-circle-08",
      component: MemberIndex,
      layout: "/admin",
    },
    {
      path: "#products",
      name: "Data Produk",
      icon: "ni ni-bag-17",
      component: ProductIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/brands",
          name: "Kelola Merek",
          icon: "ni ni-trophy",
          component: BrandIndex,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Kelola Kategori",
          icon: "ni ni-folder-17",
          component: CategoryIndex,
          layout: "/admin",
        },
        {
          path: "/products",
          name: "Kelola Produk",
          icon: "ni ni-bag-17",
          component: ProductIndex,
          layout: "/admin"
        },
        {
          path: "/vouchers",
          name: "Kelola Voucher",
          icon: "ni ni-diamond",
          component: VouchersIndex,
          layout: "/admin"
        },
        {
          path: "/promos",
          name: "Kelola Promo",
          icon: "ni ni-bell-55",
          component: PromoIndex,
          layout: "/admin"
        }
      ]
    },
    {
      path: "#spendings",
      name: "Data Pengeluaran",
      icon: "fas fa-coins",
      component: SpendingIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/spending_report",
          name: "Report Pengeluaran",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexSpending,
          layout: "/admin",
        },
        {
          path: "/spending_categories",
          name: "Kategori Pengeluaran",
          icon: "ni ni-bullet-list-67",
          component: SpendingCategoryIndex,
          layout: "/admin",
        },
        // {
        //   path: "/spendings",
        //   name: "Kelola Pengeluaran",
        //   icon: "fas fa-coins",
        //   component: SpendingIndex,
        //   layout: "/admin",
        // },
        {
          path: "/payments",
          name: "Pengajuan Pencairan Saldo",
          icon: "fas fa-coins",
          component: PaymentsIndex,
          layout: "/admin",
        },
        {
          path: "/purchases",
          name: "Purchasing",
          icon: "fas fa-coins",
          component: PurchaseIndex,
          layout: "/admin",
        },
      ]
    },
    {
      path: "#config",
      name: "Pengaturan",
      icon: "fa fa-wrench",
      component: null,
      layout: null,
      child_open: false,
      childs: [
        {
          path: "/staffs",
          name: "Kelola Staff",
          icon: "ni ni-user-run",
          component: StaffIndex,
          layout: "/admin",
        },
        {
          path: "/virtuals",
          name: "Kelola Saldo & Poin",
          icon: "fas fa-coins",
          component: VirtualsIndex,
          layout: "/admin",
        },
        {
          path: "/blogs",
          name: "Kelola Blog",
          icon: "ni ni-tv-2",
          component: BlogsIndex,
          layout: "/admin",
        },
        {
          path: "/rewards",
          name: "Kelola Reward",
          icon: "ni ni-trophy",
          component: RewardsIndex,
          layout: "/admin",
        },
        {
          path: "/group_users",
          name: "Level Mitra",
          icon: "ni ni-spaceship",
          component: GroupUserIndex,
          layout: "/admin",
        },
        {
          path: "/expeditions",
          name: "Expedisi Pengiriman",
          icon: "ni ni-delivery-fast",
          component: Expeditions,
          layout: "/admin",
        },
        {
          path: "/accounts",
          name: "Kelola Akun",
          icon: "ni ni-credit-card",
          component: AccountIndex,
          layout: "/admin",
        },
        {
          path: "/payment_numbers",
          name: "Nomor Rekening",
          icon: "ni ni-credit-card",
          component: PaymentNumbers,
          layout: "/admin",
        },
        {
          path: "/wa_settings",
          name: "WA Setting",
          icon: "ni ni-chat-round",
          component: WaSettingsIndex,
          layout: "/admin",
        },
        {
          path: "/landing_presses",
          name: "Landing Press",
          icon: "ni ni-tv-2",
          component: LandingPressesIndex,
          layout: "/admin",
        },
        {
          path: "/request_landing_presses",
          name: "Request Landing Page",
          icon: "ni ni-tv-2",
          component: RequestLpIndex,
          layout: "/admin",
        }
      ]
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/scan-code",
          name: "Scan QR Code",
          icon: "ni ni-mobile-button",
          component: ScanCode,
          layout: "/admin",
        },
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        },
        {
          path: "/marketingkit",
          name: "Marketing Kit",
          icon: "ni ni-album-2",
          component: MarketingKitIndex,
          layout: "/admin",
        },
      ]
    }
  ];

  var access_pages_finance = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "#reports",
      name: "Report",
      icon: "ni ni-sound-wave",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/report_members",
          name: "Penyebaran Domisili Mitra",
          icon: "ni ni-square-pin",
          component: ReportMembers,
          layout: "/admin",
        },
        {
          path: "/daily_report",
          name: "Report Harian",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexDaily,
          layout: "/admin",
        },
        {
          path: "/monthly_report",
          name: "Report Bulanan",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMonthly,
          layout: "/admin",
        },
        {
          path: "/member_report",
          name: "Report Mitra",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMember,
          layout: "/admin",
        },
        {
          path: "/region_report",
          name: "Report Daerah",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexRegion,
          layout: "/admin",
        },
        {
          path: "/product_report",
          name: "Report Produk",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexProduct,
          layout: "/admin",
        },
        // {
        //   path: "/expedition_report",
        //   name: "Report Expedisi",
        //   icon: "ni ni-chart-bar-32",
        //   component: ReportIndexExpedition,
        //   layout: "/admin",
        // }
      ]
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-cart",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/products",
      name: "Data Produk",
      icon: "ni ni-bag-17",
      component: ProductIndex,
      layout: "/admin"
    },
    {
      path: "#spendings",
      name: "Data Pengeluaran",
      icon: "fas fa-coins",
      component: SpendingIndex,
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/spending_report",
          name: "Report Pengeluaran",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexSpending,
          layout: "/admin",
        },
        {
          path: "/spending_categories",
          name: "Kategori Pengeluaran",
          icon: "ni ni-bullet-list-67",
          component: SpendingCategoryIndex,
          layout: "/admin",
        },
        {
          path: "/spendings",
          name: "Kelola Pengeluaran",
          icon: "fas fa-coins",
          component: SpendingIndex,
          layout: "/admin",
        },
        {
          path: "/payments",
          name: "Pengajuan Pencairan Saldo",
          icon: "fas fa-coins",
          component: PaymentsIndex,
          layout: "/admin",
        },
        {
          path: "/purchases",
          name: "Purchasing",
          icon: "fas fa-coins",
          component: PurchaseIndex,
          layout: "/admin",
        },
      ]
    },
    {
      path: "/virtuals",
      name: "Kelola Saldo & Poin",
      icon: "fas fa-coins",
      component: VirtualsIndex,
      layout: "/admin",
    },
    {
      path: "/members",
      name: "Kelola Mitra",
      icon: "ni ni-circle-08",
      component: MemberIndex,
      layout: "/admin",
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        },
        {
          path: "/marketingkit",
          name: "Marketing Kit",
          icon: "ni ni-album-2",
          component: MarketingKitIndex,
          layout: "/admin",
        },
      ]
    }
  ];

  var access_pages_inventory = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-cart",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/products",
      name: "Data Produk",
      icon: "ni ni-bag-17",
      component: ProductIndex,
      layout: "/admin"
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/scan-code",
          name: "Scan QR Code",
          icon: "ni ni-mobile-button",
          component: ScanCode,
          layout: "/admin",
        },
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        },
        {
          path: "/marketingkit",
          name: "Marketing Kit",
          icon: "ni ni-album-2",
          component: MarketingKitIndex,
          layout: "/admin",
        },
      ]
    }
  ];

  var access_pages_cs = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "/shops",
      name: "Keranjang Belanja",
      icon: "ni ni-cart",
      component: ShopIndex,
      layout: "/admin",
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-chart-bar-32",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/members",
      name: "Kelola Mitra",
      icon: "ni ni-circle-08",
      component: MemberIndex,
      layout: "/admin",
    },
    {
      path: "#reports",
      name: "Report",
      icon: "ni ni-sound-wave",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/report_members",
          name: "Penyebaran Domisili Mitra",
          icon: "ni ni-square-pin",
          component: ReportMembers,
          layout: "/admin",
        },
        {
          path: "/daily_report",
          name: "Report Harian",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexDaily,
          layout: "/admin",
        },
        {
          path: "/monthly_report",
          name: "Report Bulanan",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMonthly,
          layout: "/admin",
        },
        {
          path: "/member_report",
          name: "Report Mitra",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexMember,
          layout: "/admin",
        },
        {
          path: "/region_report",
          name: "Report Daerah",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexRegion,
          layout: "/admin",
        },
        {
          path: "/product_report",
          name: "Report Produk",
          icon: "ni ni-chart-bar-32",
          component: ReportIndexProduct,
          layout: "/admin",
        },
        // {
        //   path: "/expedition_report",
        //   name: "Report Expedisi",
        //   icon: "ni ni-chart-bar-32",
        //   component: ReportIndexExpedition,
        //   layout: "/admin",
        // }
      ]
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        },
        {
          path: "/marketingkit",
          name: "Marketing Kit",
          icon: "ni ni-album-2",
          component: MarketingKitIndex,
          layout: "/admin",
        },
      ]
    }
  ];

  var access_pages_merchant = [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2",
      component: Index,
      layout: "/admin",
    },
    {
      path: "/orders",
      name: "Data Pesanan",
      icon: "ni ni-cart",
      component: OrderIndex,
      layout: "/admin",
    },
    {
      path: "/products",
      name: "Data Produk",
      icon: "ni ni-bag-17",
      component: ProductIndex,
      layout: "/admin"
    },
    {
      path: "/purchases",
      name: "Purchasing",
      icon: "fas fa-coins",
      component: PurchaseIndex,
      layout: "/admin",
    },
    {
      path: "#",
      name: "Tools",
      icon: "ni ni-diamond",
      component: '',
      layout: "/admin",
      child_open: false,
      childs: [
        {
          path: "/track_shipping",
          name: "Track Pengiriman",
          icon: "ni ni-app",
          component: TrackShippingIndex,
          layout: "/admin",
        },
        {
          path: "/check_shipping",
          name: "Cek Ongkir",
          icon: "ni ni-delivery-fast",
          component: CheckShippingIndex,
          layout: "/admin",
        }
      ]
    }
  ];

  let currentPage = window.location.pathname.split('/')[2]
  
  if(currentUser && currentUser.type === 'Adminmaster'){
    var routesSidebar = access_pages_admin
  }else if(currentUser && currentUser.type === 'Company'){
    var routesSidebar = access_pages_company
    if(currentPage.includes('companies')){
      history.push("/");
      alert('Anda tidak di izinkan mengakses halaman ini');
    }
  }else if(currentUser && currentUser.type === 'Staff' && currentUser.role_access === 'admin'){
    var routesSidebar = access_pages_company_admin
    if(currentPage.includes('companies') || currentPage.includes('spendings') || currentPage.includes('billings') ){
      history.push("/");
      alert('Anda tidak di izinkan mengakses halaman ini');
    }
  }else if(currentUser && currentUser.type === 'Staff' && currentUser.role_access === 'finance'){
    var routesSidebar = access_pages_finance
    var access = [{access_name: 'index'}, {access_name: 'product'}, {access_name: 'order'}, {access_name: 'report'}, {access_name: 'members'}, {access_name: 'spending'}, {access_name: 'shipping'}, {access_name: 'marketingkit'}, {access_name: 'account'}, {access_name: 'payments'}, {access_name: 'virtuals'}, {access_name: 'purchase'}]
    var check = access.filter((p) => currentPage.includes(p.access_name))
    if(check.length < 1){
      history.push("/");
      alert('Anda tidak di izinkan mengakses halaman ini');
    }
  }else if(currentUser && currentUser.type === 'Staff' && currentUser.role_access === 'inventory'){
    var routesSidebar = access_pages_inventory
    var access = [{access_name: 'index'}, {access_name: 'product'}, {access_name: 'order'}, {access_name: 'shipping'}, {access_name: 'marketingkit'}, {access_name: 'account'}, {access_name: 'scan-code'}]
    var check = access.filter((p) => currentPage.includes(p.access_name))
    if(check.length < 1){
      history.push("/");
      alert('Anda tidak di izinkan mengakses halaman ini');
    }
  }else if(currentUser && currentUser.type === 'Staff' && currentUser.role_access === 'cs'){
    var routesSidebar = access_pages_cs
    var access = [{access_name: 'index'}, {access_name: 'order'}, {access_name: 'report'}, {access_name: 'member'}, {access_name: 'shops'}, {access_name: 'shipping'}, {access_name: 'marketingkit'}, {access_name: 'account'}]
    var check = access.filter((p) => currentPage.includes(p.access_name))
    
    if(check.length < 1){
      history.push("/");
      alert('Anda tidak di izinkan mengakses halaman ini');
    }
  }else if(currentUser && currentUser.type === 'Merchant'){
    var routesSidebar = access_pages_merchant
    var access = [{access_name: 'index'}, {access_name: 'product'}, {access_name: 'order'}, {access_name: 'shipping'}, {access_name: 'marketingkit'}, {access_name: 'account'}, {access_name: 'purchase'}]
    var check = access.filter((p) => currentPage.includes(p.access_name))
    
    if(check.length < 1){
      history.push("/");
      alert('Anda tidak di izinkan mengakses halaman ini');
    }
  }

  return (
    <div className={currentSidebar && currentSidebar === true ? 'sidebar-hide' : ''}>
      { currentCompany ? (
        <Helmet>
          <title>{currentCompany ? currentCompany.full_name : 'Dashboard'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={currentCompany.company_icon_url} />
          <link rel="icon" href={currentCompany.company_icon_url} />
        </Helmet>
      ) : null}

      <Sidebar
        {...props}
        routes={routesSidebar}
        logo={{
          innerLink: "/admin/index",
          imgSrc: currentLogo,
          imgAlt: "Logo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </div>
  );
};

export default Admin;
