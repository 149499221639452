import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PaymentsIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  
  const [id, setId] = useState('');
  const [status, setStatus] = useState('pending');
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  
  const [report, setReport] = useState({});
  const [companies, setCompanies] = useState([]);
  const [members, setMembers] = useState([]);
  const [data, setData] = useState([]);
  const [claim, setClaim] = useState({});
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');

  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const toggleExport = () => {
    setModalExport(!modalExport);
  }

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      setClaim(dt);

      if(dt){
        setId(dt.id);
        setStatus(dt.status);
        setImageSrc(dt.image_url);
        setImageFile('');
      }else{
        setId('');
        setStatus('pending');
        setImageSrc('');
        setImageFile('');
      }
    }else{
      setId('');
      setStatus('pending');
      setImageSrc('');
      setImageFile('');
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('status', status);
    
    if(id){
      API.put(`claim_moneys/${id}`, fd, config )
        .then(res => {
          setId('');
          setStatus('pending');
          setImageSrc('');
          setImageFile('');
          setSpinnerSave(false);
          const dt = res.data.data;
          const oldData = data;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          if (new_data !== -1){
            oldData[new_data] = dt
            setData(oldData);
          }
          // loadData('');
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const loadData = (keyword, start_date, end_date) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company', start_at: start_date, end_at: end_date  }};
    API.get(`claim_moneys`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })

    API.get(`claim_money_reports`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company', start_at: searchStartDate, end_at: searchEndDate } };
    
    API.get(`claim_moneys`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, searchStartDate, searchEndDate);
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(search, start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(search, searchStartDate, end_date);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const setCompanyLoad = (company_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, by_user_type: 'Member', pagination: true }};        
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setMembers(data);
      })
  }

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  const exportData = () => {
    toggleExport();
    setLoadingExport(true);
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { pagination: true, key: search, include: 'company', start_at: searchStartDate, end_at: searchEndDate } };
    API.get(`claim_exports`, config)
      .then(res => {
        const data = res.data.report;
        setSpinner(false)
        setLoadingExport(false);
        toggleExport();
        setLinkDownload(data);
      })
      .catch(error => {
        setSpinner(false)
        setLoadingExport(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff' && currentUser.role_access === 'finance'){
      setCompanyLoad(currentUser.company_id)
    }

  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Data Pencairan Saldo</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <FormGroup className="form-img-select">
                <div class='avatar-user'>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: 120,
                      height: 120
                    }}
                  />
                </div>
              </FormGroup>

              <div className='row'>
                <div className='col-12'>
                  <Label className='label-form-group'>Status Pembayaran</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-star" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value=''>Pilih Status</option>
                      {
                        statusCollectionPayment.map((c) => {
                          return (
                            <option key={c.value} value={c.value}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                
                {
                  claim ? (
                    <div className='col-md-12 table-responsive' style={{padding: 0}}>
                      <table className="align-items-center table-flush table" responsive>
                        <tbody>
                          <tr>
                            <td width='30%'>Pembayaran</td>
                            <td>
                              <div style={styles.status(claim.status)}>
                                {claim.status_txt}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Kode Faktur</td>
                            <td>
                              <span className="mb-0 text-sm">
                                {claim.code}
                              </span> 
                            </td>
                          </tr>
                          <tr>
                            <td>Nama Mitra</td>
                            <td>{claim.full_name} ({claim.username})</td>
                          </tr>
                          <tr>
                            <td>Nilai</td>
                            <td>
                              <b>{numberFormat(claim.virtual_money)}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Transfer Ke</td>
                            <td>{claim.payment_number}</td>
                          </tr>
                          {
                            claim.paid_date ? (
                              <tr>
                                <td>Tanggal Pembayaran</td>
                                <td>{claim.paid_date}</td>
                              </tr>
                            ) : null
                          }
                          <tr>
                            <td>Tanggal Dibuat</td>
                            <td>{claim.created_at}</td>
                          </tr>
                          <tr>
                            <td>Deskripsi</td>
                            <td>{claim.title}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null
                }
                
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          PENDING
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.pending ? numberFormat(parseInt(report.pending)) : numberFormat(0)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-grey text-white rounded-circle shadow">
                          <i className="fas fa-coins" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          SUDAH DIBAYAR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.paid ? numberFormat(report.paid) : numberFormat(0)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa-coins" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          DIBATALKAN
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.cancelled ? numberFormat(parseInt(report.cancelled)) : numberFormat(0)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-coins" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-3'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-3'>
                    <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                  </div>
                  <div className='col-sm-3'>
                    <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                  </div>
                  <div className='col-sm-1'>
                    <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Faktur</th>
                    <th scope="col">Nama Mitra</th>
                    { currentUser && currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null }
                    <th scope="col">Nilai</th>
                    <th scope="col">Transfer Ke</th>
                    <th scope="col">Transaksi</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="9" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px' className="">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.status)}>
                                  {u.status_txt}
                                </div>
                              </td>
                              <td>
                                <span className="mb-0 text-sm">
                                  {u.code}
                                </span> 
                              </td>
                              <td>{u.full_name}</td>
                              { currentUser && currentUser.type === 'Adminmaster' ? (
                                <td>[{u.user_id}] {u.company ? u.company.full_name : ''}</td>
                              ) : null }
                              <td>
                                <b>{numberFormat(u.virtual_money)}</b>
                              </td>
                              <td>{u.payment_number}</td>
                              <td>{u.paid_date}</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PaymentsIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}