import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

const VirtualsIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [memberId, setMemberId] = useState('');
  const [status, setStatus] = useState('');
  const [price, setPrice] = useState(0);
  const [point, setPoint] = useState(0);
  const [orderCode, setOrderCode] = useState('');

  const [queryMember, setQueryMember] = useState('');
  const [companies, setCompanies] = useState([]);
  const [itemMembers, setItemMembers] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');

  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const toggleExport = () => {
    setModalExport(!modalExport);
  }

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setTitle('');
    setMemberId('');
    setStatus('');
    setPrice(0);
    setPoint(0);
    setOrderCode('');
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('title', title);
    fd.append('user_id', memberId);
    fd.append('status', status);
    fd.append('price', price);
    fd.append('point', point);
    fd.append('order_code', orderCode);
    
    API.post('virtuals', fd, config )
      .then(res => {
        setTitle('');
        setMemberId('');
        setStatus('');
        setPrice(0);
        setPoint(0);
        setOrderCode('');
        loadData('');
        setSpinnerSave(false);
        toggle();
      })
      .catch(error => {
        setSpinnerSave(false);
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
    
  }

  const loadData = (keyword, start_date, end_date) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, start_at: start_date, end_at: end_date, include: 'user, staff_name'  }};
    API.get(`virtuals`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', start_at: searchStartDate, end_at: searchEndDate, include: 'user, staff_name' } };
    
    API.get(`virtuals`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(search, start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(search, searchStartDate, end_date);
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const handleOnSearchMember = (string, results) => {
    console.log(string, results)
    findMember(string)
  }

  const handleOnHoverMember = (result) => {
    console.log(result)
  }

  const handleOnSelectMember = (item) => {
    let _member = itemMembers.filter((m) => m.id === item.id)[0]
    setMemberId(_member.id);
  }

  const handleOnFocusMember = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    // return item;
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
  }

  const findMember = (query) => {
    setQueryMember(query);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: query, by_user_type: 'Member', include: '' }};
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        setItemMembers(data);
      })
  }

  const exportData = () => {
    toggleExport();
    setLoadingExport(true);
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { pagination: true, key: search, start_at: searchStartDate, end_at: searchEndDate, include: 'user, staff_name' } };
    API.get(`virtual_exports`, config)
      .then(res => {
        const data = res.data.report;
        setSpinner(false)
        setLoadingExport(false);
        toggleExport();
        setLinkDownload(data);
      })
      .catch(error => {
        setSpinner(false)
        setLoadingExport(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');

  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Saldo & Poin</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <FormGroup className="mb-3">
            <div className='row'>
              <div className="input-field col-sm-12 autocomplete-field">
                <label className="active" for="icon_prefix">
                  Cari & Pilih Mitra
                </label>
                <ReactSearchAutocomplete
                  items={itemMembers}
                  onSearch={handleOnSearchMember}
                  onHover={handleOnHoverMember}
                  onSelect={handleOnSelectMember}
                  formatResult={formatResult}
                  />
                <br/>
              </div>
              <div className='col-12'>
                <Label className='label-form-group'>Kode Pesanan (Optional Isi Jika Terkait Pesanan)</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Kode Pesanan"
                    value={orderCode} onChange={e => setOrderCode(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className='col-12'>
                <Label className='label-form-group'>Keterangan</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Keterangan"
                    value={title} onChange={e => setTitle(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className='col-12'>
                <Label className='label-form-group'>Status</Label>
                <InputGroup className="input-group-alternative">
                  <Input type="select" value={status} onChange={e => setStatus(e.target.value)}>
                    <option value=''>Pilih Status</option>
                    <option value='in'>Ditambahkan</option>
                    <option value='out'>Ditarik</option>
                  </Input>
                </InputGroup>
              </div>
              <div className='col-6'>
                <Label className='label-form-group'>Jumlah Saldo</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Saldo"
                    value={price} onChange={e => setPrice(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className='col-6'>
                <Label className='label-form-group'>Jumlah Poin</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Poin"
                    value={point} onChange={e => setPoint(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost()}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-4'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                    </div>
                  </div>
                  <div className='col-sm-1'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i></Button>
                  </div>
                  <div className='col-sm-1'>
                    <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Status</th>
                    <th scope="col">Nama Mitra</th>
                    <th scope="col">Keterangan</th>
                    <th scope="col">Saldo</th>
                    <th scope="col">Poin</th>
                    <th scope="col">Staff</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="7" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td>{u.id}</td>
                              <td>
                                <div style={styles.status(u.status)}>
                                  {u.status === 'in' || u.status === 'on' ? 'Dikirim' : 'Dikurangi'}
                                </div>
                              </td>
                              <td>
                                {u.username} 
                              </td>
                              <td>
                                {u.title} 
                              </td>
                              <td style={styles.status(u.status)}>
                                {u.price > 0 ? (
                                  <>
                                    {(u.status === 'in' || u.status === 'on') ? '+' : '-'} {u.price} 
                                  </>
                                ) : null }
                              </td>
                              <td style={styles.status(u.status)}>
                                {u.point > 0 ? (
                                  <>
                                    {(u.status === 'in' || u.status === 'on') && u.point > 0 ? '+' : '-'} {u.point} 
                                  </>
                                ) : null }
                              </td>
                              <td>{u.staff_name ? u.staff_name : ''}</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default VirtualsIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'in' || status === 'on' ? 'green' : 'red',
    paddingBottom: 2,
    borderRadius: 5,
  })
}