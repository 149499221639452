import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const SpendingShow = () => {
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();
  const {id} = useParams();

  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [companyId, setCompanyId] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [spendingCategoryId, setSpendingCategoryId] = useState('');
  const [status, setStatus] = useState('pending');
  const [description, setDescription] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [price, setPrice] = useState(0);
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [spendingCategories, setSpendingCategories] = useState([]);
  const [paymentNumbers, setPaymentNumbers] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const statusCollection = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Diproses', 'value':'process'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]


  const loadData = () => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: '', include: 'company' }};
    API.get(`spendings/${id}`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setStatus(data.status);
        setSpinner(false);

        setCode(data.code);
        setTitle(data.title);
        setSpendingCategoryId(data.spending_category_id);
        setStatus(data.status);
        setDescription(data.description);
        setTransactionDate(data.transaction_date);
        setFrom(data.from);
        setTo(data.to);
        setPrice(data.price);
        setCompanyLoad(data.user_id);
        setImageSrc(data.image_url);
        setImageFile('');
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
      
  }

  const setCompanyLoad = (company_id) => {
    setCompanyId(company_id);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false }};    
    API.get(`spending_categories`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setSpendingCategories(data);
      })
    
    API.get(`payment_numbers`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
  }

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('code', code);
    fd.append('title', title);
    fd.append('spending_category_id', spendingCategoryId);
    fd.append('status', status);
    fd.append('description', description);
    fd.append('transaction_date', transactionDate);
    fd.append('from', from);
    fd.append('to', to);
    fd.append('price', price);
    fd.append('user_id', companyId);
    
    API.put(`spendings/${id}`, fd, config )
      .then(res => {
        setSpinnerSave(false);
        loadData('');
        toggle();
      })
      .catch(error => {
        setSpinnerSave(false);
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
    
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData('');

  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Data Pengeluaran</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <FormGroup className="form-img-select">
                <div class='avatar-user'>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: 120,
                      height: 120
                    }}
                  />
                </div>
              </FormGroup>

              <div className='row'>
                { currentUser && currentUser.type === 'Adminmaster' ? (
                  <>
                    <div className='col-6'>
                      <Label className='label-form-group'>Perusahaan</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={companyId} onChange={e => setCompanyLoad(e.target.value)}>
                          <option value=''>Pilih Perusahaan</option>
                          {
                            companies.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.full_name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                    <div className='col-6'>
                      <Label className='label-form-group'>Kategori Pengeluaran</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-list" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={spendingCategoryId} onChange={e => setSpendingCategoryId(e.target.value)}>
                          <option value=''>Pilih Kategori</option>
                          {
                            spendingCategories.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                  </>
                ) : (
                  <div className='col-12'>
                    <Label className='label-form-group'>Kategori Pengeluaran</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-list" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={spendingCategoryId} onChange={e => setSpendingCategoryId(e.target.value)}>
                        <option value=''>Pilih Kategori</option>
                        {
                          spendingCategories.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>
                ) }
                <div className='col-6'>
                  <Label className='label-form-group'>Kode Faktur</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-flag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kode Pengeluaran"
                      value={code} onChange={e => setCode(e.target.value)}
                    />
                  </InputGroup>
                </div>  
                <div className='col-6'>
                  <Label className='label-form-group'>Status</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-star" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value=''>Pilih Status</option>
                      {
                        statusCollection.map((c) => {
                          return (
                            <option key={c.value} value={c.value}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Nama Pengeluaran</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nama Produk"
                      value={title} onChange={e => setTitle(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Keterangan</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      type="textarea"
                      placeholder="Keterangan"
                      value={description} onChange={e => setDescription(e.target.value)}
                    />
                  </InputGroup>
                </div>
                
                <div className='col-6'>
                  <Label className='label-form-group'>Nilai Pengeluaran</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        RP
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Harga Modal"
                      value={price} onChange={e => setPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Tanggal Transaksi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <DatePicker id= "example-datepicker" value={transactionDate} onChange={e => setTransactionDate(e)} />
                  </InputGroup>
                </div>

                <div className='col-6'>
                  <Label className='label-form-group'>Dibayar Dari</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-credit-card" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={from} onChange={e => setFrom(e.target.value)}>
                      <option value=''>Pilih No.Rekening</option>
                      {
                        paymentNumbers.map((c) => {
                          return (
                            <option key={c.bank+'-'+c.number} value={c.bank+'-'+c.number}>{c.bank+'-'+c.number}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-sm-6'>
                  <Label className='label-form-group'>Tujuan Pembayaran</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-credit-card" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tujuan Rekening Pembayaran"
                      value={to} onChange={e => setTo(e.target.value)}
                    />
                  </InputGroup>
                </div>
                
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>
                      FAKTUR {data.code}
                      <UncontrolledDropdown className='pull-right'>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem href="#" onClick={(e) => toggle(data.id)}>
                            Ubah
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-8'>
                    <b>PERUSAHAAN</b>
                    <br/>
                    <div>
                      {data.company ? data.company.full_name : ''}
                    </div>
                    <br/>
                    <b>KODE</b>
                    <br/>
                    <div>
                      {data.code}
                    </div>
                    <br/>
                    <b>KATEGORI</b>
                    <br/>
                    <div>
                      {data.spending_category_name}
                    </div>
                    <br/>
                    <b>DIBUAT</b>
                    <br/>
                    <div>
                      {data.created_at}
                    </div>
                    <br/>
                    <b>TANGGAL TRANSAKSI</b>
                    <br/>
                    <div>
                      {data.transaction_date}
                    </div>

                    { data.description ? (
                      <>
                        <br/>
                        <b>KETERANGAN</b>
                        <br/>
                        <div>
                          {data.description}
                        </div>
                      </>
                    ) : null }
                  </div>
                  <div className='col-md-4'>
                    <img src={data.image_url} className='img-factor'/>
                  </div>
                </div>

                <br/>
                <Table className="align-items-center table-flush" responsive>
                  <tr>
                    <td>
                      <b>JUDUL</b>
                    </td>
                    <td>
                      <b>PEMBAYARAN DARI</b>
                    </td>
                    <td>
                      <b>TUJUAN PEMBAYARAN</b>
                    </td>
                    <td>
                      <b>STATUS PEMBAYARAN</b>
                    </td>
                    <td>
                      <b>HARGA</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {data.title}
                    </td>
                    <td>
                      {data.from}
                    </td>
                    <td>
                      {data.to}
                    </td>
                    <td>
                      <div style={styles.status(data.status)}>
                        {data.status_payment}
                      </div>
                    </td>
                    <td>
                      {numberFormat(data.price)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='text-right'>
                      <b>TOTAL PEMBAYARAN</b>
                    </td>
                    <td>
                      <b>{numberFormat(data.price)}</b>
                    </td>
                  </tr>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SpendingShow;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}